import { useSelector } from 'react-redux'
import Computing from './Computing'
import AnalysisResult from './AnalysisResult'
import {
  Container,
  TitleWrapper,
  DiagnosisTitle
} from './styles'

export function StepFour () {
  const { cases: caseStatus } = useSelector(state => state)

  if (caseStatus === 'idle') return (
    <Container>
      <Computing/>
    </Container>
  )

  return (
    <Container>
      <AnalysisResult status={caseStatus}/>
    </Container>
  )
}

export default StepFour
