import { useSelector } from "react-redux";
import { URLS } from "../../../const/nav";
export const useDeleteStudy = () => {
    const { user } = useSelector(state => state);
    const deleteStudy = async (id) => {
        try{
            const r = await fetch(`${URLS.BACKEND_URL}/estudios/borrar/${id}`, {
                                    method: 'PUT',
                                    headers: {
                                        'Content-Type': 'application/json',
                                        'Authorization': `${user.token}`
                                    },
                                })
            if (r.ok) {
                return await r.json();
            }
        }catch(error){
            console.error("Error al obtener la respuesta:", error);
        }
    }

    return { deleteStudy };
}