import styled, { css } from 'styled-components'
import { colors } from '../../../const'



export const TittlePercentage = styled.p`
  color: ${colors.lightBlack};
  font-size: 0.875rem;
  font-weight: 600;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin: 0;
`

export const TitleLoading = styled.p`
  color: ${colors.lightBlack};
  font-size: 0.875rem;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0;
`



export const ContainerStatus = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 90%;
  width: 30%;
`

export const PrincipalImageStatus = styled.div(({status}) => css`
  border-top:  2px solid rgba(211, 218, 230, 0.5);
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  height: 10%;
  padding-top: 2%;
  width: 80%;
`
)




