import { useState } from 'react';
import { URLS } from '../../../const/nav';

export const useGetQuality = () => {
    const [iluminacion, setIluminacion] = useState(null);
    const [contraste, setContraste] = useState(null);
    const [claridad, setClaridad] = useState(null);

    const getQualityImage = async (id) => {
        try {
            const response = await fetch(`${URLS.BACKEND_URL}/imagenes/calidad/${id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${JSON.parse(sessionStorage.getItem('_user')).token}`
                }
            });
            
            const data = response.json();
            console.log(data)
            return data;
        } catch (error) {
            console.error("Error fetching quality:", error);
            return null;
        }
    };


const getStatus = async (id) => {
    try {
        const response = await fetch(`${URLS.BACKEND_URL}/imagenes/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${JSON.parse(sessionStorage.getItem('_user')).token}`
            }
        });

        const data = await response.json();
        console.log(data)
        return {
            iluminacion: data.iluminacion,
            contraste: data.contraste,
            enfoque: data.enfoque,
            status: data.calidad
        };
    } catch (error) {
        console.error("Error fetching quality:", error);
        return null;
    }
};


    return { getStatus, getQualityImage };
}
