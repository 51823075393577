import { URLS } from "../../../const/nav";

const URL = {
    'technical': `/estudios/`,
    'ophthalmologist': `/estudios/informados`,
    'patient': `/estudios/propios`
}

export const searchAllEvaluations = async (token, rol) => {

    try{
        const r = await fetch(URLS.BACKEND_URL+URL[rol], {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${token}`
            },
        });
        if (r.ok) {
            return await r.json();
        } else {
            console.error('Error en la solicitud:', r.status, r.statusText);
            return null;
        }
    } catch (error) {
      console.error('Error durante la solicitud:', error);
      return null;
    }      
}