import { useDispatch } from 'react-redux'
import { stepIncrement, stepDecrement } from '../redux/steps'

export function useStepper () {
  const dispatch = useDispatch()

  
  const handleNextStep = () => {
    dispatch(stepIncrement())
  }

  const handlePreviousStep = () => {
    dispatch(stepDecrement())
  }

  return {
    handleNextStep,
    handlePreviousStep,
  }
}

