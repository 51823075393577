export const LIST_OF_CASES = [
  { id: 1, date: '24 Dic. 2021', patient: 'Juan Martin Alvarez', dni: '35875152',  examType: 'binocular', report: false, status: 'pendent', iaDx: 'positive', drDx: ''},
  { id: 2, date: '24 Dic. 2021', patient: 'Juan Martin Alvarez', dni: '35875152',  examType: 'binocular', report: false, status: 'pendent', iaDx: 'negative', drDx: ''},
  { id: 3, date: '24 Dic. 2021', patient: 'Juan Martin Alvarez', dni: '35875152',  examType: 'monocular-right', report: false, status: 'notReferred', iaDx: 'positive', drDx: 'negative'},
  { id: 4, date: '24 Dic. 2021', patient: 'Juan Martin Alvarez', dni: '35875152',  examType: 'binocular', report: false, status: 'pendent', iaDx: 'positive', drDx: 'negative'},
  { id: 5, date: '24 Dic. 2021', patient: 'Juan Martin Alvarez', dni: '35875152',  examType: 'monocular-left', report: false, status: 'notReferred', iaDx: 'positive', drDx: 'negative'},
  { id: 6, date: '24 Dic. 2021', patient: 'Juan Martin Alvarez', dni: '35875152',  examType: 'binocular', report: true, status: 'informed', iaDx: 'positive', drDx: 'positive'},
  { id: 7, date: '24 Dic. 2021', patient: 'Juan Martin Alvarez', dni: '35875152',  examType: 'monocular-right', report: true, status: 'informed', iaDx: 'negative', drDx: 'positive'},
]

export const STATUS_TEXT = {
  informed: 'Informado',
  informedDR: 'Informado',
  pendent: 'Informe Pendiente',
  notReferred: 'No Referido'
}

export const DX_TEXT = {
  positive: 'Riesgo',
  disease: 'Enfermedad'
}

export const EXAM_TYPE_TEXT = {
  2: 'Monocular izquierdo',
  1: 'Monocular derecho',
  0: 'Binocular'
}
