import { useState } from 'react';
import { useSelector } from 'react-redux';
import { URLS } from '../../../const/nav';

export const useSearchStudyService = () => {
    const [response, setResponse] = useState(null);
    const {user} = useSelector(state => state);
    const searchStudy = async (id) => {
        try {
            const r = await fetch(`${URLS.BACKEND_URL}/estudios/${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${user.token}`
                },
            });
    
            if (r.status === 404) {
                // Handle error 404 (Not Found) here
                console.log('Estudio no encontrado.');
                setResponse(null); // Otra acción en caso de error 404, si es necesario
            } else if (r.ok) {
                setResponse(await r.json());
            } else {
                // Otro manejo de errores aquí
                console.error(`Error en la solicitud: ${r.status}`);
                setResponse(null);
            }
        } catch (error) {
            // Manejo de errores generales aquí
            console.error('Error al realizar la solicitud:', error);
            setResponse(null);
        }
    }
    return { response, searchStudy };
}