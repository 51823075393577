import { URLS } from "../const/nav";
export const useRestoreImage = () => {

    
    const getImage = async (id) => {
        try {
            const response = await fetch(`${URLS.BACKEND_URL}/imagenes/archivo/${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${JSON.parse(sessionStorage.getItem('_user')).token}`
                }
            });
            return response;
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    const getHeatMapImages = async (id) => {
        try {
            const responseNHM = await fetch(`${URLS.BACKEND_URL}/imagenes/cropped/${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${JSON.parse(sessionStorage.getItem('_user')).token}`
                }
            });
            const responseHM = await fetch(`${URLS.BACKEND_URL}/imagenes/heatmap/${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${JSON.parse(sessionStorage.getItem('_user')).token}`
                }
            });
            return {NHM: URL.createObjectURL(await responseNHM.blob()), 
                    HM: URL.createObjectURL(await responseHM.blob())};
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    const restoreImage = async (id, heatMap) => {
        try {
            const response = await fetch(`${URLS.BACKEND_URL}/imagenes/${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${JSON.parse(sessionStorage.getItem('_user')).token}`
                }
            });
            const img = await getImage(id);
            const imgHeatMap = heatMap ? await getHeatMapImages(id) : null;
            const data = await response.json();
            console.log('data', data)
            return {
                id: data.idHash,
                eye: data.ojo,
                image: URL.createObjectURL(await img.blob()),
                heatMapImage: imgHeatMap ? imgHeatMap : null,
                iluminacion: data.iluminacion,
                contraste: data.contraste,
                enfoque: data.enfoque,
                probabilidad: data.probabilidad,
                riesgoIA: data.hayRiesgo_IA,
                status: data.calidad
                // Falta nombre
            };
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    return {restoreImage}
}