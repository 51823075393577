import styled from 'styled-components'
import { colors } from '../../../const'

export const Container = styled.div`  
  background-color: ${colors.white};
  box-shadow:
    0px 1px 1px rgba(0, 0, 0, 0.07),
    0px 2px 4px rgba(0, 0, 0, 0.05),
    0px 5px 10px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  display: flex;
  flex-wrap: wrap;
  font-family: 'Inter';
  padding-top: 30px;
  margin-top: 30px;
  width: 100%;
`

export const TitleWrapper = styled.div`
  align-items: center;
  display: flex;
  padding: 20px 20px 6px 30px;
  width: 100%;
`

export const DiagnosisTitle = styled.h1`
  color: ${colors.lightBlack};
  font-family: 'Inter';
  font-size: 1rem;
  font-weight: 700;
  margin-right: 6px;
`
