import EditIcon from '../../../../../assets/icons/pencil.png'
import OpenEyeIcon from '../../../../../assets/icons/open_eye.svg'
import CloseEyeIcon from '../../../../../assets/icons/close_eye.svg'
import { usePostStudy } from '../../../../../hooks/NewCase/usePostStudy'
import { useState } from 'react'
import { useSearchPatient } from '../../../../../hooks/NewCase/useSearchPatient'
import { SessionExpired } from '../../../../SessionExpired'
import { useSearchStudyService } from '../../../../../hooks/NewCase/Service/useSearchStudyService'
import { setEvaluations } from '../../../../../redux/evaluations'
import { useUpdateUserEvaluations } from '../../../../../hooks/useUpdateUserEvaluations'
import { useDispatch } from 'react-redux'
import { useRequiered } from '../../../../../hooks/NewCase/Requiered/useRequiered'
import {
  Container,
  TitleWrapper,
  NewUserTitle,
  DniTitle,
  FillSectionWrapper,
  FillSection,
  InputWrapper,
  Label,
  InputTemplate,
  InputFooter,
  SeparatorFillSection,
  InputWithIconWrapper,
  SmallInputWithIconWrapper,
  InputForIcon,
  IconForInput,
  SeparatorTitleSection,
  MultipleInputContainer,
  SmallInputWrapper,
  EyeTypeAndLabelWrapper,
  EyeTypeWrapper,
  RadioEyeWrapper,
  EyeIconsWrapper,
  RadioLabel,
  RadioInput,
  ButtonsWrapper,
  Button,
  TitleContainer,
  TitleSection,
  Title,
  InputTemplateExtended,
  EyeIcon
} from './styles'
import { useEffect } from 'react'

export function PhaseTwo ({ backPhase, nextPhase }) {
  const { postStudy } = usePostStudy()
  const { patient, patientData, search } = useSearchPatient()
  const { response, searchStudy } = useSearchStudyService()
  const { isValid, dataError, checkRequired, buttonAvailable} = useRequiered("PhaseTwo")
  const dispatch = useDispatch()
  const { updateUserEvaluations } = useUpdateUserEvaluations()
  const [data, setData] = useState({
    fecha: new Date(),
    paciente_id:`${patientData && patientData.id}`,
    tipo:'0',
    informe:'COMPLETAR',
    revisarProfesional:'false',
    altura:'',
    peso:'',
    informacionAdicional:''
  })

  useEffect(()=>{
    setData({...data, paciente_id:`${patientData && patientData.id}`})
  },[patientData])

  useEffect(() => {
    search()
  }, [])

  useEffect(() => {
    if (response) {
      dispatch(setEvaluations(response));
      updateUserEvaluations();
      nextPhase();
    }
  }, [response]);

  useEffect(() => {
    const valid = async () => {
      if(isValid){
        const response = await postStudy(data);
        await searchStudy(response.idEstudio);
      }else{
        return
      }
    }
    valid()
  }, [isValid])
  


  const changeHandler = event => {
    setData(prevData => {
      const newData = { ...prevData, [event.target.name]: event.target.value };
      checkRequired(newData, false, true);
      return newData;
    });
  };

  const saveStudy = async () => {
    checkRequired(data)
  };



  return (
    <Container>
      <SessionExpired />
      <TitleWrapper>
        <TitleContainer>
          <NewUserTitle> Datos de consulta </NewUserTitle>
          <DniTitle> DNI {patient && patient.dni} </DniTitle>
        </TitleContainer>
      </TitleWrapper>
      
      <TitleSection>
        <Title>Datos del estudio</Title>
        <SeparatorTitleSection />
        <Title>Información adicional</Title>
      </TitleSection>

      <FillSectionWrapper>
        <FillSection>
        
          <InputWrapper>
            <Label> Fecha de estudio </Label>
            <InputTemplate defaultValue={new Date().toISOString().split('T')[0]} onChange={changeHandler} name='fecha' type='date' />
          </InputWrapper>

          <EyeTypeAndLabelWrapper>
            <Label> Tipo de estudio </Label>
            <EyeTypeWrapper>
              <RadioEyeWrapper>
                <EyeIconsWrapper>
                  <EyeIcon src={OpenEyeIcon} alt='open-eye' />
                  <EyeIcon src={OpenEyeIcon} alt='open-eye' />
                </EyeIconsWrapper>
                <RadioLabel>
                  <RadioInput onChange={changeHandler} defaultChecked type='radio' name='tipo' value='0' />
                  Binocular
                </RadioLabel>
              </RadioEyeWrapper>

              <RadioEyeWrapper>
                <EyeIconsWrapper>
                  <EyeIcon src={OpenEyeIcon} alt='open-eye' />
                  <EyeIcon src={CloseEyeIcon} alt='closed-eye' />
                </EyeIconsWrapper>
                <RadioLabel>
                  <RadioInput onChange={changeHandler} type='radio' name='tipo' value='1' />
                  Monocular derecho
                </RadioLabel>
              </RadioEyeWrapper>

              <RadioEyeWrapper>
                <EyeIconsWrapper>
                  <EyeIcon src={CloseEyeIcon} alt='closed-eye' />
                  <EyeIcon src={OpenEyeIcon} alt='open-eye' />
                </EyeIconsWrapper>
                <RadioLabel>
                  <RadioInput onChange={changeHandler} type='radio' name='tipo' value='2' />
                  Monocular izquierdo
                </RadioLabel>
              </RadioEyeWrapper>
            </EyeTypeWrapper>
          </EyeTypeAndLabelWrapper>
        </FillSection>

        <SeparatorFillSection />

        <FillSection>
          <MultipleInputContainer>
            <SmallInputWrapper percentageWidth='45'>
              <Label>
                <Label> Altura </Label>
                <Label attenuated> (cm) </Label>
              </Label>
              <SmallInputWithIconWrapper error={dataError.altura}>
                <InputForIcon onChange={changeHandler} name='altura' type='number' />
              </SmallInputWithIconWrapper>
            </SmallInputWrapper>

            <SmallInputWrapper percentageWidth='45'>
              <Label>
                <Label> Peso </Label>
                <Label attenuated> (kg) </Label>
              </Label>
              <SmallInputWithIconWrapper error={dataError.peso}>
                <InputForIcon onChange={changeHandler} name='peso' type='number' />
              </SmallInputWithIconWrapper>
            </SmallInputWrapper>
          </MultipleInputContainer>


          <InputWrapper extended>
            <Label>
              <Label> Información complementaria </Label> 
              <Label attenuated> (Opcional) </Label>
            </Label>
            
            <InputTemplateExtended onChange={changeHandler} name='informacionAdicional'/>
          </InputWrapper>
        </FillSection>
      </FillSectionWrapper>

      <ButtonsWrapper>
        <Button onClick={backPhase}> Volver </Button>
        <Button highlighted available={buttonAvailable} onClick={saveStudy}> Guardar </Button>
      </ButtonsWrapper>

    </Container>
  )
}

export default PhaseTwo
