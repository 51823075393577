import { useState } from 'react';
import { useSelector } from 'react-redux';
import { URLS } from '../../../const/nav';
export const useSearchPatientService = () => {
    const [response, setResponse] = useState(null);
    const { user } = useSelector(state => state);
    const searchPatient = async (data, id=false) => {
        try {
            const parameters = id?`/${data}`:`?dni=${data && data.dni}&genero=${data && data.genero}`;
            const r = await fetch(`${URLS.BACKEND_URL}/pacientes${parameters}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${user.token}`
                },
            });
    
            if (r.status === 404) {
                // Handle error 404 (Not Found) here
                console.log('Paciente no encontrado, dar de alta paciente.');
                setResponse(null); // Otra acción en caso de error 404, si es necesario
            } else if (r.ok) {
                const res = await r.json();
                setResponse(res);
            } else {
                // Otro manejo de errores aquí
                console.error(`Error en la solicitud: ${r.status}`);
                setResponse(null);
            }
        } catch (error) {
            // Manejo de errores generales aquí
            console.error('Error al realizar la solicitud:', error);
            setResponse(null);
        }
    };    
    return { response, searchPatient };
}