import styled, { css } from 'styled-components'

const STATUS_COLOR = {
  error: '#FF0505'
}

export const PositionContainer = styled.div(
  ({ isVisible }) => css`
    bottom: 5%;
    display: flex;
    justify-content: center;
    opacity: ${isVisible ? '1' : '0'};
    position: fixed;
    width: 100vw;
  `
)

export const Container = styled.div(
  ({ status }) => css`
    align-items: center;
    display: flex;
    font-family: 'Inter';
    height: 70px;
    max-width: 500px;
    width: 100%;
  `
)

export const LeftBorder = styled.div(
  ({ status }) => css`
    background-color: ${STATUS_COLOR[status]};
    border-bottom-left-radius: 8px;
    border-top-left-radius: 8px;
    height: 100%;
    width: 20px;
  `
)

export const Text = styled.p`
  align-items: center;
  background-color: rgba(240, 240, 240, 0.8);
  display: flex;
  flex-grow: 1;
  height: 100%;
  justify-content: center;
  margin: 0;
  padding: 0 5%;
  text-align: center;
`

export const IconWrapper = styled.div`
  align-items: center;
  background-color: rgba(240, 240, 240, 0.8);
  display: flex;
  height: 100%;
  justify-content: center;
  width: 40px;
`

export const Icon = styled.img`
  cursor: pointer;
`
