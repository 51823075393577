import { setEvaluations } from '../redux/user';
import { useDispatch, useSelector } from 'react-redux';
import { searchAllEvaluations } from '../views/Evaluations/Service/EvaluationService';

export const useUpdateUserEvaluations = () => {
    const { user } = useSelector(state => state);
    const dispatch = useDispatch();

    const updateUserEvaluations = async () => {
        const response = await searchAllEvaluations(user.token, user.role)
        dispatch(setEvaluations(response))
    }

    return { updateUserEvaluations };
}