import styled, { css } from 'styled-components'
import { colors, withScrollbarStyled } from '../../../const'
import { STATUS } from '../../../const/status'

const bigDotColor = (isAnImage) => {
  if (isAnImage) return colors.white
  return 'transparent'
}

const percentageColor = (value) => {
  if (value < 50) return '#FF0505'
  return colors.primary
}

export const Container = styled.div(
  ({ isVisible }) => css`
    display: ${isVisible ? 'flex' : 'none'};
    flex-direction: column;
    font-family: 'Inter';
    height: 100%;
    width: 100%;
  `
)

export const ImagesContainer = styled.div`
  display: flex;
  height: 100%;
  padding: 2% 0 0;
  width: 100%;
`

export const ThumbnailsContainer = styled.ol`
  border-right: 2px solid rgba(211, 218, 230, 0.5);
  margin: 0;
  max-height: 450px;
  overflow-y: auto;
  padding: 0 0 2% 0;
  width: 18%;

  counter-reset: counter-name 0;
  ${withScrollbarStyled}
`

export const ThumbnailCounterWrapper = styled.li(
  () => css`
    margin: 0 auto;
    position: relative;

    &::before {
      color: ${colors.primary};
      content: counter(counter-name);
      counter-increment: counter-name;
      width: 10px;
      height: 10px;
      z-index: 2;
      position: absolute;
    }
  `
)

export const ThumbnailWrapper = styled.div(
  ({ image, status, selected }) => css`
    align-items: center;
    background-color: #F5F7FA;
    border: 4px solid ${selected ? '#0077CC' : 'transparent'};
    cursor: pointer;
    display: flex;
    height: 64px;
    justify-content: center;
    margin: 12px auto;
    position: relative;
    width: 64px;

    &::before {
      background-color: ${bigDotColor(image)};
      border-radius: 50%;
      content: '';
      height: 14px;
      right: -6px;
      position: absolute;
      top: -6px;
      width: 14px;
      z-index: 1;
    }

    &::after {
      background-color: ${STATUS[status]};
      border-radius: 50%;
      content: '';
      height: 10px;
      right: -4px;
      position: absolute;
      top: -4px;
      width: 10px;
      z-index: 1;
    }
  `
)

export const Icon = styled.img`
  cursor: pointer;
`


export const PrincipalImageInfo = styled.div(({display}) => css`
  border-bottom: 1px solid rgba(171, 180, 196, 0.3);
  display: ${display ? 'flex' : 'none'};
  align-items: center;
  height: 50px;
  justify-content: space-between;
  width: 100%;
  
`
)

export const PrincipalImageText = styled.p`
  color: #0077CC;
  font-size: 0.875rem;
  overflow: hidden;
  padding-left: 3%;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 90%;
  transition: opacity 0.3s ease-in-out;
`


export const PrincipalImageContainer = styled.div(
  ({ inTheCenter }) => css`
    align-items: ${inTheCenter ? 'center' : 'flex-start'};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 0 0 3%;
    margin-bottom: 2%;
    margin-top: 2%;
    align-items: center;
    width: 100%;
  `
)


export const DataPercentageContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  height: 100%;
  width: 100%;
`

export const TittlePercentage = styled.p`
  color: #0077CC;
  font-size: 0.875rem;
  font-weight: 600;
  margin: 0;
`

export const ValuePercentage = styled.p(({value}) => css`
  color: ${value > 50 ? colors.white : colors.lightBlack};
  font-size: 0.875rem; 
  font-weight: 600;
  margin: 0;
`)

export const ContainerStatus = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-top: 2%;
  margin-bottom: 2%;
  height: 100%;
  width: 50%;
`

export const PrincipalImageStatus = styled.div(({status}) => css`
  border-top:  2px solid rgba(211, 218, 230, 0.5);
  height: 50px;
  margin-right: 3%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 40%;
  padding-top: 5%;
  padding-bottom: 5%;
  width: 80%;
`
)

export const PrincipalImageStatusPercentage = styled.div(({value}) => css`
  border-radius: 6px;
  border: 1px solid ${percentageColor(value)};
  height: 50%;
  display: flex;
  justify-content: flex-start;
  width: 80%;
`
)

export const Percentage = styled.p(({value}) => css`
  color: black;
  display: flex;
  font-size: 0.875rem;
  font-weight: 600;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: ${value === 100 ? '6px' : '0'};
  border-top-right-radius: ${value === 100 ? '6px' : '0'};
  margin: 0;
  width: ${value}%;
  background-color: ${percentageColor(value)};

`
)


export const PrincipalImageShadow = styled.div(
  () => css`
    align-items: center;
    background-color: #F5F7FA;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    height: 20vw;
    justify-content: center;
    margin-top: 5%;
    width: 20vw;
  `
)

export const UploadImageButton = styled.button`
  align-items: center;
  background-color: ${colors.primary};
  border: none;
  border-radius: 6px;
  color: ${colors.white};
  cursor: pointer;
  display: flex;
  height: 40px;
  justify-content: center;
  max-width: 214px;
  outline: none;
  width: 100%;
`

export const UploadImageFooter = styled.footer`
  color: #98A2B3;
  font-size: 0.875rem;
  font-weight: 600;
  margin-top: 5%;
`

export const HiddenInputFile = styled.input`
  display: none;
  left: -1000;
  position: absolute;
  top: -1000;
  visibility: none;
`
