// Actions
const MODIFY_LEFT_EYE_IMAGES = '@images/modify-left-eye-images'
const MODIFY_RIGHT_EYE_IMAGES = '@images/modify-right-eye-images'
const MODIFY_LEFT_EYE_CURRENT_IMAGE = '@images/modify-left-current-image'
const MODIFY_RIGHT_EYE_CURRENT_IMAGE = '@images/modify-right-current-image'
const MODIFY_LEFT_EYE_HEATMAP_IMAGES = '@images/modify-left-heat-map-images'
const MODIFY_RIGHT_EYE_HEATMAP_IMAGES = '@images/modify-right-heat-map-images'
const IMAGES_RESET = '@images/reset'

const INITIAL_STATE = {
  leftEye: {
    currentImage: null,
    images: [
      { id: -1, image: null, heatMapImage: null, name: null, status: 'none', position: 1 },
      { id: -2, image: null, heatMapImage: null, name: null, status: 'none', position: 2 },
      { id: -3, image: null, heatMapImage: null, name: null, status: 'none', position: 3 },
      { id: -4, image: null, heatMapImage: null, name: null, status: 'none', position: 4 },
      { id: -5, image: null, heatMapImage: null, name: null, status: 'none', position: 5 },
      { id: -6, image: null, heatMapImage: null, name: null, status: 'none', position: 6 },
      { id: -7, image: null, heatMapImage: null, name: null, status: 'none', position: 7 }
    ]
  },
  rightEye: {
    currentImage: null,
    images: [
      { id: -1, image: null, heatMapImage: null, name: null, status: 'none', position: 1 },
      { id: -2, image: null, heatMapImage: null, name: null, status: 'none', position: 2 },
      { id: -3, image: null, heatMapImage: null, name: null, status: 'none', position: 3 },
      { id: -4, image: null, heatMapImage: null, name: null, status: 'none', position: 4 },
      { id: -5, image: null, heatMapImage: null, name: null, status: 'none', position: 5 },
      { id: -6, image: null, heatMapImage: null, name: null, status: 'none', position: 6 },
      { id: -7, image: null, heatMapImage: null, name: null, status: 'none', position: 7 }
    ]
  }
}

// Reducer
export default function imagesReducer (state = INITIAL_STATE, action) {
  switch (action.type) {
    case MODIFY_LEFT_EYE_IMAGES:
      return {
        ...state,
        leftEye: {
          currentImage: state.leftEye.currentImage,
          images: action.payload
        }
      }
    case MODIFY_RIGHT_EYE_IMAGES:
      return {
        ...state,
        rightEye: {
          currentImage: state.rightEye.currentImage,
          images: action.payload
        }
      }
    case MODIFY_LEFT_EYE_CURRENT_IMAGE:
      return {
        ...state,
        leftEye: {
          currentImage: action.payload,
          images: state.leftEye.images
        }
      }
    case MODIFY_RIGHT_EYE_CURRENT_IMAGE:
      return {
        ...state,
        rightEye: {
          currentImage: action.payload,
          images: state.rightEye.images
        }
      }
    case MODIFY_LEFT_EYE_HEATMAP_IMAGES:
      return {
        ...state,
        leftEye: {
          heatMapImage: action.payload
        }
      }
    case MODIFY_RIGHT_EYE_HEATMAP_IMAGES:
      return {
        ...state,
        rightEye: {
          heatMapImage: action.payload
        }
      }
    case IMAGES_RESET:
      return INITIAL_STATE
    default:
      return state
  }
}

// Action creator
export function modifyLeftEyeImages (images) {
  return { type: MODIFY_LEFT_EYE_IMAGES, payload: images }
}

export function modifyRightEyeImages (images) {
  return { type: MODIFY_RIGHT_EYE_IMAGES, payload: images }
}

export function modifyLeftEyeCurrentImage (images) {
  return { type: MODIFY_LEFT_EYE_CURRENT_IMAGE, payload: images }
}

export function modifyRightEyeCurrentImage (image) {
  return { type: MODIFY_RIGHT_EYE_CURRENT_IMAGE, payload: image }
}

export function modifyLeftEyeHeatMapImages (images) {
  return { type: MODIFY_LEFT_EYE_HEATMAP_IMAGES, payload: images }
}

export function modifyRightEyeHeatMapImages (images) {
  return { type: MODIFY_RIGHT_EYE_HEATMAP_IMAGES, payload: images }
}

export function resetImages () {
  return { type: IMAGES_RESET }
}
