import { useState } from 'react';
import { useSelector } from 'react-redux'

export const useValidateToken = () => {
    const { user } = useSelector(state => state);
    const validate = () => {
        try {
            const token = user.token; // Obtener el token de sessionStorage
            const tokenData = JSON.parse(atob(token.split('.')[1])); // Decodificar el payload del token
            const expirationTime = tokenData.exp * 1000; // Multiplicar por 1000 para convertir a milisegundos
            // Comparar la fecha de expiración con la fecha y hora actuales
            return {
                isLogged: Date.now() <= expirationTime,
                sessionExpired: Date.now() > expirationTime
            }
        } catch (error) {
            return {
                isLogged: false,
                sessionExpired: false
            }
        }
    }

    // Devolver la función de validación sin invocarla
    return { validate };
}
