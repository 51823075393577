import {
  Container,
  ButtonsWrapper,
  Separator,
  Button,
} from './styles'
import IAQualityControl from '../IAQualityControl'
import { useSelector } from 'react-redux'

export function GenerateReport ({ save, warningAction}) {
  const { evaluations } = useSelector(state => state)

  console.log(evaluations)
  return (
    <Container>
      <IAQualityControl study={evaluations} />

      <Separator />

      <ButtonsWrapper>
        <Button type='secondary' onClick={() => warningAction('cancel')}> Cancelar </Button>
        <Button type='secondary' onClick={save}> Guardar y cerrar </Button>
        <Button
          type='primary'
          onClick={() => warningAction('send')}
        >
          Enviar informe
        </Button>
      </ButtonsWrapper>
    </Container>
  )
}

export default GenerateReport
