import { useEffect, useState } from "react";
import { useSearchStudyService } from "./Service/useSearchStudyService";
import { useSearchPatient } from "./useSearchPatient";

export const useSearchStudy = () => {

    const { response, searchStudy } = useSearchStudyService();
    const [ study, setStudy] = useState(null);
    const { patientData, search } = useSearchPatient();

    const getStudy = async (id) => {
        await searchStudy(id);
    };

    useEffect(() => {
        const fetchData = async () => {
          try {
            const responseData = response;
            setStudy(responseData);
          } catch (error) {
            console.error("Error al obtener la respuesta:", error);
          }
        };
      
        fetchData();
      } , [response]);
    
    useEffect(() => {
        const fetchData = async () => {
          try {
            console.log('estudio ',study)
            if(study)
                await search(study.paciente_id);
          } catch (error) {
            console.error("Error al obtener la respuesta:", error);
          }
        };
        fetchData();
    }, [study]);
    

    return { study, patientData ,getStudy };
};