import { useEffect } from "react"
import { useState } from "react"
import { useValidateToken } from "../../hooks/useValidateToken"
import { SessionExpiredModal } from "./SessionExpiredModal"
import { useModal } from "../../hooks/useModal"

export const SessionExpired = () => {
    const [modalRef, openModal, closeModal] = useModal()
    const { validate } = useValidateToken()
    const [isExpired, setIsExpired] = useState(false)


    useEffect(() => {
        const{sessionExpired} = validate()
        setIsExpired(sessionExpired)
    }, [])

    const confirm = () => {
        closeModal()
        window.location.href = '/login'
      }
  
    useEffect(() => {
      if (isExpired) {
        openModal()
      }
      
    }, [isExpired])

    return (
        <>
            {isExpired && <SessionExpiredModal ref={modalRef} confirm={confirm} />}
        </>
    )
}