import styled from 'styled-components'
import { colors } from '../../const'

export const Container = styled.div(({focus}) => `
  align-items: center;
  backdrop-filter: ${focus ? 'blur(5px)' : 'none'};
  background-color: ${focus ? 'rgba(0, 0, 0, 0.3)' : 'rgba(0, 0, 0, 0.2)'};
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 2;
`)

export const ModalTemplate = styled.div(({focus, imageControl}) => `
  background-color: ${colors.white};
  border-radius: 6px;
  box-shadow:
    0px 3px 9px rgba(0, 0, 0, 0.13),
    0px 9.4px 24px rgba(0, 0, 0, 0.09),
    0px 22px 43px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  font-family: 'Inter';
  max-width: ${focus ? (imageControl?'80%':'500px') : '60%'};
  padding: 24px;
  height: ${(focus && imageControl) || !focus ? '80%' : 'auto'};
  width: 100%;
  position: relative;
`
)


export const Icon = styled.img`
  align-self: flex-end;
  cursor: pointer;
  height: 10px;
  width: 10px;
`
