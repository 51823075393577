import { Redirect } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { resetSnackbar } from '../../redux/snackbar'
import {
  Stepper,
  StepOne,
  StepTwo,
  StepThree,
  StepFour,
  CaseSended,
  Snackbar,
} from '../../components'
import { stepContinue, stepNotContinue, stepReset } from '../../redux/steps'
import { USER_STATUS } from '../../const'
import { Container } from './styles'
import { useEffect } from 'react'

const STEPS_VIEW = {
  1: StepOne,
  2: StepTwo,
  3: StepThree,
  4: StepFour,
}

export function NewCase () {
  const { steps, snackbar, user, evaluations } = useSelector(state => state)
  const dispatch = useDispatch()

  if (user.status === USER_STATUS.LOCKED || user.role === 'ophthalmologist') return <Redirect to='/home' />

  const handleCloseSnackbar = () => {
    dispatch(resetSnackbar())
  }

  useEffect(() => {
    if(Object.keys(evaluations).length){
      dispatch(stepContinue())
    }else{
      dispatch(stepNotContinue())
    }
  }, [])

  const CurrentStepView = STEPS_VIEW[steps.current] || <h1> Step sin datos! <br /> Step: {steps.current} </h1>

  return (
    <Container>
      {snackbar.active && (
        <Snackbar status={snackbar.status} close={handleCloseSnackbar}>
          {snackbar.text}
        </Snackbar>
      )}

      {!steps.continue && <Stepper />}
      {steps.completed
        ? <CaseSended />
        : steps.continue ? <StepThree/> : <CurrentStepView />
      }
      
    </Container>
  )
}

export default NewCase
