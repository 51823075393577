import styled, { css } from 'styled-components'
import { Link as LinkReactRouter } from 'react-router-dom'
import { colors } from '../../const'
import LockIcon from '../../assets/icons/lock-info.svg'

export const Container = styled.div`
  align-items: center;
  background-color: ${colors.white};
  box-shadow: 
    0px 1px 4px -1px rgba(0, 0, 0, 0.08),
    0px 3px 8px -1px rgba(0, 0, 0, 0.06),
    0px 8px 12px -1px rgba(0, 0, 0, 0.05),
    0px 15px 15px -1px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  margin: 0 14px;
  min-height: 290px;
  max-width: 290px;
  position: relative;
  width: 100%;
`

export const Button = styled.button(
  ({ highlighted }) => css`
    align-items: center;
    background-color: ${highlighted ? colors.primary : colors.secondary};
    border: none;
    border-radius: 6px;
    color: ${highlighted ? colors.white : colors.primary};
    cursor: pointer;
    display: flex;
    font-family: 'Inter';
    font-size: 0.875rem;
    font-weight: 500;
    height: 40px;
    justify-content: center;
    text-decoration: none;
    width: 100%;
  `
)

export const IconWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  width: 100%;
`

export const IconOption = styled.img`
  height: 90px;
  margin-top: 22px;
  width: auto;
`

export const Link = styled(LinkReactRouter)`
  margin-bottom: 32px;
  max-width: 246px;
  text-decoration: none;
  width: 100%;
`

export const LinkContainer = styled.div`
  margin-bottom: 32px;
  max-width: 246px;
  text-decoration: none;
  width: 100%;
`

export const DummyLink = styled.div`
  margin-bottom: 32px;
  max-width: 246px;
  text-decoration: none;
  width: 100%;
`

export const DummyButton = styled.div`
  align-items: center;
  background-color: rgba(105, 112, 125, 0.1);
  border: none;
  border-radius: 6px;
  color: #ABB4C4;
  cursor: not-allowed;
  display: flex;
  font-family: 'Inter';
  font-size: 0.875rem;
  font-weight: 500;
  height: 40px;
  justify-content: center;
  text-decoration: none;
  width: 100%;
`

export const FloatingMessage = styled.span`
  color: #0071C2;
  font-size: 0.875rem;
  font-weight: 500;
  position: absolute;
  text-align: center;
  top: 10px;
`

export const FloatingMessageWithIcon = styled.span`
  color: #0071C2;
  font-size: 0.875rem;
  font-weight: 500;
  position: absolute;
  text-align: center;
  top: 10px;

  &::before {
    content: url(${LockIcon});
    margin-right: 6px;
  }
`