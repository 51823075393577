import{DataPercentageContainer, PrincipalImageStatusPercentage, Percentage, ValuePercentage} from './styles'

export function PercentageValues({values, dxIA=null}){

    const obtainValue = () => {
      if(dxIA===null)
        return values
      else
        return dxIA?values:(1-values)
    }  


    const value = (obtainValue() * 100).toFixed(2);
    return (
        <DataPercentageContainer value={value} dxIA={dxIA}>
        <PrincipalImageStatusPercentage value={value} dxIA={dxIA}>
          <Percentage value={value}  dxIA={dxIA}/>
        </PrincipalImageStatusPercentage>
        <ValuePercentage> {value}% </ValuePercentage>
        </DataPercentageContainer>
    )
}