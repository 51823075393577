import { useLogin } from "./Service/useLogin";
import { useDispatch } from "react-redux";
import { setEvaluations, toggleToAdminUser, toggleToPatientUser, toggleToOphthalmologistUser, toggleToTechnicalUser} from '../../redux/user'
import { searchAllEvaluations } from "../../views/Evaluations/Service/EvaluationService";
import { getUserData } from "./UserData/getUserData";
import { updateDataUser } from "../../redux/user";
import { useEffect } from "react";
const TECHNICAL = 'technical'
const PATIENT = 'patient'
const ADMIN = 'admin'
const OPHTHALMOLOGIST = 'ophthalmologist'

const FORMAT_ROLE = {
  'tecnico': TECHNICAL,
  'retinologo': OPHTHALMOLOGIST,
  'paciente': PATIENT,
  'admin': ADMIN
}

export const useValidate = () => {
    const { login, error } = useLogin();
    // const [ r, setR ] = useState(null);
    const dispatch = useDispatch();
    
    const validate = async (data) => {
        await login(data).then((response) => {
            assignSession(response)
        });
    };

    const fetchData = async (rol, token) => {
      const response = await searchAllEvaluations(token, rol)
      const dataRes = await getUserData(rol, token)
      if (rol === TECHNICAL){
        dispatch(toggleToTechnicalUser(token))
        dispatch(setEvaluations(response))
      } 
      if (rol === PATIENT){
        dispatch(toggleToPatientUser(token))
        dispatch(setEvaluations(response))
        dispatch(updateDataUser(dataRes))
      } 
      if (rol === OPHTHALMOLOGIST){
        dispatch(toggleToOphthalmologistUser(token))
        dispatch(setEvaluations(response))
      } 
      // if (rol === ADMIN) dispatch(toggleToAdminUser(token))
    }
    
    const assignSession = async (response) => {
      if(response === null) return
      
      const userToStore = { role: response.rol, status: null, token: response.token, evaluations: null}
      sessionStorage.setItem('_user', JSON.stringify(userToStore))
      await fetchData(FORMAT_ROLE[response.rol] ,response.token)
      window.location = '/home'
    }

    return { validate, assignSession, error };
    }