import styled, { css } from 'styled-components'
import { colors } from '../../../const'

export const Container = styled.div`  
  background-color: ${colors.white};
  box-shadow:
    0px 1px 1px rgba(0, 0, 0, 0.07),
    0px 2px 4px rgba(0, 0, 0, 0.05),
    0px 5px 10px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
  width: 100%;
`

export const SuccessWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 7% 0 8%;
  width: 100%;
`

export const SuccesImageWrapper = styled.div`
  width: 57px;
`

export const SuccessImage = styled.img`
  width: 100%;
`

export const SuccessTitle = styled.h1`
  color: ${colors.lightBlack};
  font-family: 'Inter';
  font-size: 1.7rem;
  font-weight: 700;
`

export const ButtonsWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  max-width: 400px;
  width: 100%;
`

export const Button = styled.button(
  ({ highlighted }) => css`
    align-items: center;
    background-color: ${highlighted ? '#005EC4' : colors.secondary};
    border: none;
    border-radius: 6px;
    color: ${highlighted ? colors.white : '#005EC4'};
    cursor: pointer;
    display: flex;
    font-family: 'Inter';
    font-size: 0.875rem;
    font-weight: 500;
    height: 40px;
    justify-content: center;
    margin: 0 2%;
    width: 100%;
  `
)
