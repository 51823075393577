import { USER_STATUS } from '../const'

// Actions
const USER_TECHNICAL = '@user/technical'
const USER_PATIENT = '@user/patient'
const USER_ADMIN = '@user/admin'
const USER_OPHTHALMOLOGIST = '@user/ophthalmologist'
const USER_STATUS_NEW = '@user/status-new'
const USER_STATUS_CONTINUE = '@user/status-continue'
const USER_STATUS_LOCKED = '@user/status-locked'
const USER_STATUS_UNLOCK = '@user/status-unlock'
const USER_SET_EVALUATIONS = '@user/set-evaluations'
const USER_REMOVE_INCOMPLETE_EVALUATIONS = '@user/remove-incomplete-evaluations'
const USER_UPDATE_DATA = '@user/update-data'

const TECHNICAL = 'technical'
const PATIENT = 'patient'
const OPHTHALMOLOGIST = 'ophthalmologist'
const ADMIN = 'admin'

const userFromStorage = JSON.parse(sessionStorage.getItem('_user'))
const TOKEN = userFromStorage ? userFromStorage.token : null
const INITIAL_STATE = {
  role: userFromStorage ? userFromStorage.role : TECHNICAL,
  status: userFromStorage && userFromStorage.status !== null ? userFromStorage.status : USER_STATUS.NEW,
  token: TOKEN,
  evaluations: userFromStorage && userFromStorage.evaluations ? userFromStorage.evaluations : null,
  data: userFromStorage && userFromStorage.data ? userFromStorage.data : null,
}

// Función para actualizar la sesión
const updateSession = (newState) => {
  const userToStore = {
    role: newState.role,
    status: newState.status,
    token: newState.token,
    evaluations: newState.evaluations,
    data: newState.data
  };
  sessionStorage.setItem('_user', JSON.stringify(userToStore));
};

const deleteIncompleteEvaluations = (evaluations) => {
  return evaluations.filter(evaluation => evaluation.estado !== 'incompleto')
}

// Reducer
export default function userReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case USER_TECHNICAL:
      const newStateTechnical = { ...state, role: TECHNICAL, status: USER_STATUS.NEW, token: action.payload }
      updateSession(newStateTechnical)
      return newStateTechnical;
    case USER_PATIENT:
      const newStatePatient = { ...state, role: PATIENT, status: USER_STATUS.PATIENT,token: action.payload }
      updateSession(newStatePatient)
      return newStatePatient;
    case USER_ADMIN:
      const newStateAdmin = { ...state, role: ADMIN, status: USER_STATUS.NEW, token: action.payload }
      updateSession(newStateAdmin)
      console.log(newStateAdmin)
      return newStateAdmin;
    case USER_OPHTHALMOLOGIST:
      const newStateOphthalmologist = { ...state, role: OPHTHALMOLOGIST, status: USER_STATUS.NEW ,token: action.payload}
      updateSession(newStateOphthalmologist)
      return newStateOphthalmologist;
    case USER_STATUS_NEW:
      const newStateNew = { ...state, status: USER_STATUS.NEW }
      updateSession(newStateNew)
      return newStateNew;
    case USER_STATUS_CONTINUE:
      const newStateContinue = { ...state, status: USER_STATUS.CONTINUE }
      updateSession(newStateContinue)
      return newStateContinue;
    case USER_STATUS_LOCKED:
      const newStateLocked = { ...state, status: USER_STATUS.LOCKED }
      updateSession(newStateLocked)
      return newStateLocked;
    case USER_STATUS_UNLOCK:
      const newStateUnlock = { ...state, status: USER_STATUS.UNLOCK }
      updateSession(newStateUnlock)
      return newStateUnlock;
    case USER_SET_EVALUATIONS:
      const newStateEvaluations = { ...state, evaluations: action.payload }
      updateSession(newStateEvaluations)
      return newStateEvaluations;
    case USER_REMOVE_INCOMPLETE_EVALUATIONS:
      const newStateRemoveIncompleteEvaluations = { ...state, evaluations: deleteIncompleteEvaluations(state.evaluations) }
      console.log(newStateRemoveIncompleteEvaluations)
      updateSession(newStateRemoveIncompleteEvaluations)
      return newStateRemoveIncompleteEvaluations;
    case USER_UPDATE_DATA:
      const newStateData = { ...state, data: action.payload }
      updateSession(newStateData)
      return newStateData;
    case USER_REMOVE_INCOMPLETE_EVALUATIONS:
      
    default:
      return state;
  }
}

// Action creators
export function toggleToTechnicalUser (token) {
  return { type: USER_TECHNICAL, payload: token}
}

export function toggleToPatientUser (token) {
  return { type: USER_PATIENT, payload: token }
}

export function toggleToOphthalmologistUser (token) {
  return { type: USER_OPHTHALMOLOGIST, payload: token}
}

export function toggleToAdminUser (token) {
  return { type: USER_ADMIN, payload: token}
}

export function toggleToNewStatus () {
  return { type: USER_STATUS_NEW }
}

export function toggleToContinueStatus () {
  return { type: USER_STATUS_CONTINUE }
}

export function toggleToLockedStatus () {
  return { type: USER_STATUS_LOCKED }
}

export function toggleToUnlockStatus () {
  return { type: USER_STATUS_UNLOCK }
}

export function removeIncompleteEvaluations () {
  return { type: USER_REMOVE_INCOMPLETE_EVALUATIONS }
}

export function setEvaluations (evaluations) {
  return { type: USER_SET_EVALUATIONS, payload: evaluations }
}

export function updateDataUser (data) {
  return { type: USER_UPDATE_DATA, payload: data }
}