import styled, { css } from 'styled-components'
import { colors, withScrollbarStyled } from '../../const'
import ExternalLinkIcon from '../../assets/icons/external_link.png'

const getButtonColor = (type) => {
  if (type === 'primary') return colors.white
  if (type === 'secondary') return '#005EC4'
  return '#005EC4'
}

const getButtonBackground = (type) => {
  if (type === 'primary') return colors.primary
  if (type === 'secondary') return 'rgba(0, 109, 228, 0.2)'
  return colors.white
}

export const Container = styled.div(({role}) => css`
  background-color: ${colors.white};
  box-shadow:${ role=='ophthalmologist' ? 'none':`
    0px 0px 1px rgba(0, 0, 0, 0.06),
    0px 0px 2px rgba(0, 0, 0, 0.04),
    0px 0px 5px rgba(0, 0, 0, 0.04),
    0px 0px 17px rgba(0, 0, 0, 0.03)`};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  font-family: 'Inter';
  justify-content: flex-end;
  padding: 1% 2%;
  width: ${role=='ophthalmologist'?`100`:`55`}%;
`)

export const TitleWrapper = styled.div`
  align-items: center;
  border-bottom: 1px solid #D3DAE6;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 0 4% 1%;
  width: 100%;
`

export const Title = styled.h1`
  color: ${colors.lightBlack};
  flex-grow: 1;
  font-size: 1rem;
  margin: 0;
`

export const InstructionsLink = styled.a`
  color: ${colors.primary};
  font-size: 0.8rem;
  font-weight: 500;
  margin-top: 5%;
  text-align: right;
  text-decoration: none;
  width: 50%;

  &::after {
    content: url(${ExternalLinkIcon});
    margin: 0 6px;
  }

  &:hover {
    text-decoration: underline;
  }
`

export const StatusWrapper = styled.div`
  max-height: 40vh;
  overflow: auto;
  padding-right: 2%;
  width: 100%;

  ${withScrollbarStyled};

  @media screen and (max-width: 1280px) {
    max-height: 35vh;
  }
`

export const EyeInformationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0 20px;
  width: 100%;
`

export const EyeTitle = styled.h2`
  color: #000;
  font-size: 1rem;
  font-weight: 500;
  margin: 10px 0;
`

export const ButtonsWrapper = styled.footer`
  align-items: center;
  display: flex;
  gap: 20px;
  justify-content: flex-end;
  margin-bottom: 10px;
  margin-top: auto;
  width: 100%;
`

export const Button = styled.button(
  ({ type, disable }) => css`
    align-items: center;
    background-color: ${disable ? colors.secondary : getButtonBackground(type)};
    border: none;
    border-radius: 6px;
    color: ${disable ? colors.attenuated : getButtonColor(type)};
    cursor: ${disable? 'not-allowed': 'pointer'};
    display: flex;
    font-family: 'Inter';
    font-size: 0.875rem;
    font-weight: 500;
    height: 40px;
    justify-content: center;
    margin-top: 20px;
    min-width: 112px;
    padding: 0 12px;
  `
)
export const TabsWrapper = styled.div`
  display: flex;
  width: 100%;
`

export const Tab = styled.button(
  ({ active , available}) => css`
    background-color: transparent;
    border-bottom: 2px solid rgba(152, 162, 179, 0.3);
    border-left: none;
    border-right: none;
    border-top: none;
    color: ${available? active ? colors.primary : colors.darkBlack : colors.unavailable };
    cursor: ${available? 'pointer' : 'not-allowed'};
    display: flex;
    padding-bottom: 2%;
    position: relative;
    width: 50%;
    justify-content: center;
    gap: 10px;

    &::after {
      border-bottom: 2.5px solid ${active ? colors.primary : 'transparent'};
      bottom: -1px;
      content: '';
      left: 0;
      position: absolute;
      width: 100%;
    } 
  `
)

export const TabText = styled.p`
  font-family: 'Inter';
  font-size: 0.875rem;
  font-weight: 700;
  margin: 0;
  padding: 0;
`

export const Icon = styled.img`
  padding-right: 5%;
`
