// Actions
const SNACKBAR_SUCCESS = '@snackbar/success'
const SNACKBAR_WARNING = '@snackbar/warning'
const SNACKBAR_ERROR = '@snackbar/error'
const SNACKBAR_RESET = '@snackbar/reset'

const SUCCESS = 'success'
const WARNING = 'warning'
const ERROR = 'error'

const INITIAL_STATE = {
  text: '',
  active: false,
  status: null
}

// Reducer
export default function snackbar (state = INITIAL_STATE, action) {
  switch (action.type) {
    case SNACKBAR_SUCCESS:
      return {
        text: action.payload,
        active: true,
        status: SUCCESS
      }
    case SNACKBAR_WARNING:
      return {
        text: action.payload,
        active: true,
        status: WARNING
      }
    case SNACKBAR_ERROR:
      return {
        text: action.payload,
        active: true,
        status: ERROR
      }
    case SNACKBAR_RESET: {
      return INITIAL_STATE
    }
    default:
      return state
  }
}

// Action creators
export function setSuccessSnackbar (payload) {
  return { type: SNACKBAR_SUCCESS, payload }
}

export function setWarningSnackbar (payload) {
  return { type: SNACKBAR_WARNING, payload }
}

export function setErrorSnackbar (payload) {
  return { type: SNACKBAR_ERROR, payload }
}

export function resetSnackbar () {
  return { type: SNACKBAR_RESET }
}
