import { useHistory } from 'react-router-dom'
import DownloadIcon from '../../../assets/icons/download-blue.svg'
import CheckIcon from '../../../assets/icons/check_bg_green.svg'
import {
  Container,
  SuccessImage,
  SuccessWrapper,
  SuccesImageWrapper,
  SuccessTitle,
  ButtonsWrapper,
  Button,
  ButtonIcon,
} from './styles'

export function ReportSended () {
  const history = useHistory()

  return (
    <Container>
      <SuccessWrapper>
        <SuccesImageWrapper>
          <SuccessImage src={CheckIcon} alt='success' />
        </SuccesImageWrapper>

        <SuccessTitle> Estudio enviado con éxito </SuccessTitle>

        <ButtonsWrapper>
          <Button> Ver listado de evaluaciones </Button>
          <Button>
            <ButtonIcon src={DownloadIcon} alt='download' />
            Descargar PDF
          </Button>
          <Button onClick={() => history.push('/home')}> Volver al inicio </Button>
          <Button highlighted onClick={() => history.push('/home')}> Evaluar nuevo estudio </Button>

        </ButtonsWrapper>
      </SuccessWrapper>
    </Container>
  )
}

export default ReportSended
