import { useSelector } from "react-redux";
import { URLS } from "../../const/nav";

export const useFinishStudy = () => {
    const { user, evaluations } = useSelector(state => state);
    console.log(evaluations)
    const id = evaluations? evaluations.id : JSON.parse(sessionStorage.getItem('_idStudy'));
    console.log(id)
    const finishStudy = async (status) => {
        try{
            const r = await fetch(`${URLS.BACKEND_URL}/estudios/finalizar/${id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${user.token}`
                },
                body: JSON.stringify({
                    referir: status
                })
            });
            if (r.ok) {
                return await r.json();
            } else {
                console.error('Error en la solicitud:', r.status, r.statusText);
                return null;
            }
        } catch (error) {
          console.error('Error durante la solicitud:', error);
          return null;
        }      
    }

    return { finishStudy };
}