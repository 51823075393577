import { Title, Container, ReportWrapper } from "./styles"
import Continue from '../../assets/images/continue.svg'

export const PendingReport = () => {
    return (
        <Container>
            <Title>Su estudio está siendo revisado por un profesional de la salud.</Title>
            <ReportWrapper>
                <img src={Continue} alt="" />
            </ReportWrapper>
        </Container>
    )
}