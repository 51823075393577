import { useState } from "react"
import { URLS } from "../../const/nav"

export const useGetInform = () =>{
    const [data, setData] = useState(null)
    const [action, setAction] = useState('')

    const getInform = async (token, type, evaluation) => {
        setAction(type)

        if(type!=='last'){
            try{
                const url = type === 'pending' ? `${URLS.BACKEND_URL}/estudios/pendiente` : `${URLS.BACKEND_URL}/estudios/pedir`
                const response = await fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `${token}`
                    }
                })
                const d = response.status === 404 ? data===false?'no-data':false : await response.json()
                setData(d)
            }catch(error){
                console.log('No hay estudios')
            }
        }
        else{
            setData(evaluation)
        }
        
    }

    return { data, action, getInform }
}