import { URLS } from "../../../const/nav";

export const useRemoveImage = () => {

    const removeImage = async (id) => {
        try{
            const response = await fetch(`${URLS.BACKEND_URL}/imagenes/borrar/${id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${JSON.parse(sessionStorage.getItem('_user')).token}`
                }
            });
            const responseData = await response.json();
            return responseData;
        }
        catch(error){
            console.error("Error removing image:", error);
            return null;
        }
    }

    return { removeImage };
}