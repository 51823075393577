// Actions
const CASES_REFERABLE = '@cases/referable'
const CASES_NON_REFERABLE = '@cases/non-referable'
const CASES_RESET = '@cases/reset'

const IDLE = 'idle'
const NON_REFERABLE = 'nonReferable'
const REFERABLE = 'referable'

const INITIAL_STATE = IDLE

// Reducer
export default function casesReducer (state = INITIAL_STATE, action) {
  switch (action.type) {
    case CASES_NON_REFERABLE:
      return NON_REFERABLE
    case CASES_REFERABLE:
      return REFERABLE
    case CASES_RESET:
      return IDLE
    default:
      return state
  }
}

// Action creators
export function setNonReferableCases () {
  return { type: CASES_NON_REFERABLE }
}

export function setReferableCases () {
  return { type: CASES_REFERABLE }
}

export function setResetCases () {
  return { type: CASES_RESET }
}
