import { useState, useEffect } from "react"
import { useStatisticCases } from "./useStatisticCases"

export const useCases = () => {

    const [casesToShow, setCasesToShow] = useState([])
    const [allCases, setAllCases] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [cases, setCases] = useState([])
    const [casesPerPage, setCasesPerPage] = useState(10)
    const { statistics, getStatistics } = useStatisticCases()


    const calculateListOfPages = () => {
        const totalCases = cases.length
        const pages = []
        const numberOfPages = Math.ceil(totalCases / casesPerPage)
        for (let i = 1; i <= numberOfPages; i++) {
          pages.push(i)
        }
        if(pages[pages.length-1] < currentPage){
            setCurrentPage(pages[pages.length-1])
        }
        return pages
      }

    const search = (filters) => {
        const { status, search, period } = filters
        const casesFilteredByStatus = setStatusFilter(status)
        const casesFilteredBySearch = searchCasesHandler(search)
        const casesFilteredByPeriod = setMonthFilter(period)
        const casesFiltered = casesFilteredByStatus.filter(singleCase => {
            return casesFilteredBySearch.includes(singleCase)
            }).filter(singleCase => {
            return casesFilteredByPeriod.includes(singleCase)
            })
        setCases(casesFiltered)
    }

    const setStatusFilter = (status) => {
        if(status === 'all'){
            return allCases
        }else{
            const filteredCases = allCases.filter(singleCase => {
              return singleCase.estado === status
            })
            return filteredCases
        }
      }

    const setMonthFilter = (period) => {
        const filteredCases = allCases.filter(singleCase => {
          const date = new Date(singleCase.fecha)
          const actualDate = new Date()
          const month = actualDate.getMonth() - date.getMonth()
          return month <= period
        })
        return filteredCases
    }

    const casesToShowHandler = (cases, currentPage, casesPerPage) => {
        cases.sort((a, b) => {
            if (a.fecha > b.fecha) return -1
            if (a.fecha < b.fecha) return 1
            return 0
        })
        const indexOfLastCase = currentPage * casesPerPage
        const indexOfFirstCase = indexOfLastCase - casesPerPage
        const currentCases = cases.slice(indexOfFirstCase, indexOfLastCase)
        setCasesToShow(currentCases)
    }

    const searchCasesHandler = (value) => {
        if(value === ''){
            return allCases
        }else{
            const filteredCases = allCases.filter(singleCase => {
              const patientName = `${singleCase.paciente.nombre} ${singleCase.paciente.apellido}`
              const dni = `${singleCase.paciente.dni}`
              return patientName.toLowerCase().includes(value.toLowerCase()) || dni.includes(value)
            })
            return filteredCases
        }
      }


    useEffect(() => {
        if(allCases.length === 0){
            setAllCases(cases)
        }
        casesToShowHandler(cases, currentPage, casesPerPage)
    }, [cases, currentPage, casesPerPage])

    useEffect(() => {
        getStatistics(allCases)
    }, [allCases])

    return { 
        calculateListOfPages, 
        setCurrentPage,
        setCasesPerPage,
        setCases,
        search,
        statistics,
        currentPage,
        casesToShow 
    }
    
}