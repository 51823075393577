import { useSelector } from 'react-redux'
import RetinarLogo from '../../assets/images/lightblue_logo.png'
import UserOption from '../../components/UserOption'
import { SessionExpired } from '../../components/SessionExpired'
import {
  Container,
  LogoWrapper,
  Logo,
  OptionsWrapper,
  OptionsWrapperGrid,
} from './styles'

const ADMIN_OPTIONS = [
  { id: 0, text: 'ABM de Técnicos', highlighted: false },
  { id: 1, text: 'ABM de Retinólogos', highlighted: true },
  { id: 2, text: 'ABM de Admins', highlighted: true },
  { id: 3, text: 'ABM de tratamientos', highlighted: true },
  { id: 4, text: 'ABM de Nodos', highlighted: true },
  { id: 5, text: 'ABM de estudios', highlighted: true },
  { id: 6, text: 'ABM de centros de tratamiento', highlighted: true },
  { id: 7, text: 'ABM de pacientes', highlighted: true },
]

export function Home () {
  const { user } = useSelector(state => state)

  if (user.role === 'admin') return (
    <Container>
      <SessionExpired />
      <LogoWrapper>
        <Logo src={RetinarLogo} alt='logo' />
      </LogoWrapper>

      <OptionsWrapperGrid>
        {ADMIN_OPTIONS.map(option => (
          <UserOption key={option.id} highlighted={option.highlighted} type='admin'>
            {option.text}
          </UserOption>
        ))}
      </OptionsWrapperGrid>
    </Container>
  )

  return (
    <Container>
      <SessionExpired />
      <LogoWrapper>
        <Logo src={RetinarLogo} alt='logo' />
      </LogoWrapper>

      <OptionsWrapper>
        <UserOption type='evaluations' />
        <UserOption type='case' />
      </OptionsWrapper>
    </Container>
  )
}

export default Home
