import { colors } from "../../../../const"
import styled from "styled-components"

export const TittlePercentage = styled.p`
  color: ${colors.lightBlack};
  font-size: 0.875rem;
  font-weight: 600;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin: 0;
`

export const ContainerStatus = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 90%;
  width: 30%;
`
