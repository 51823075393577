import styled from 'styled-components'

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 3% 0 5%;
  width: 100%;
`

export const DiagnosisSpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
`

export const DiagnosisSpinner = styled.div`
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #09f;
  border-radius: 50%;
  height: 64px;
  width: 64px;

  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
`

export const StatusTitle = styled.h1`
  color: #0066B1;
  font-size: 1.75rem;
  font-weight: 700;
  margin: 20px 0 30px;
`

export const StatusSubtitle = styled.h2`
  font-family: 'Open Sans';
  font-size: 1.125rem;
  font-weight: 600;
  margin: 0 0 30px;
`
