import { useEffect, useState } from 'react'
import { useStepper } from '../../../hooks/useStepper'
import { PhaseOne } from './Phases/PhaseOne'
import { PhaseTwo } from './Phases/PhaseTwo'
import { PhaseThree } from './Phases/PhaseThree'

export function StepTwo () {
  const [phase, setPhase] = useState(1)
  const { handleNextStep, handlePreviousStep } = useStepper()

  const handleBackPhase = () => {
    setPhase(prevPhase => prevPhase - 1)
  }

  const handleNextPhase = () => {
    setPhase(prevPhase => prevPhase + 1)
  }

  if (phase === 1) {
    return (
      <PhaseOne backStep={handlePreviousStep} nextPhase={handleNextPhase} />
    )
  }

  if (phase === 2) {
    return (
      <PhaseTwo backPhase={handleBackPhase} nextPhase={handleNextPhase} />
    )
  }

  if (phase === 3) {
    return (
      <PhaseThree nextStep={handleNextStep} />
    )
  }

  return null
}

export default StepTwo
