import { URLS } from "../../const/nav";

export const postPatient= async (data) => {
    return fetch(`${URLS.BACKEND_URL}/pacientes/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `${JSON.parse(sessionStorage.getItem('_user')).token}`
        },
        body: JSON.stringify(data)
    })
    .then(response => {
        if (response.ok) {
            return response.json();
          } else {
            console.error('Error en la solicitud:', response.status, response.statusText);
            return null;
          }
    })
    .catch(error => {
        console.error('Error durante la solicitud:', error);
        return null;
    });
}

const dataFormatter = (data) => {
    // Filtrar elementos que tienen algún valor
    const filteredData = Object.fromEntries(
        Object.entries(data).filter(([key, value]) => value !== undefined && value !== null && value !== '')
    );
    return filteredData;
};
export const updatePatient = async (data, id) => {
    const formatedData=dataFormatter(data);
    return fetch(`${URLS.BACKEND_URL}/pacientes/${id}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `${JSON.parse(sessionStorage.getItem('_user')).token}`
        },
        body: JSON.stringify(formatedData)
    })
    .then(response => {
        if (response.ok) {
            return response.json();
          } else {
            console.error('Error en la solicitud:', JSON.stringify(data), response.status, response.statusText);
            return null;
          }
    })
    .catch(error => {
        console.error('Error durante la solicitud:', error);
        return null;
    });
}