import {
  Container,
  DiagnosisSpinnerWrapper,
  DiagnosisSpinner,
  StatusTitle,
  StatusSubtitle
} from './styles'

export function Computing () {
  return (
    <Container>
      <DiagnosisSpinnerWrapper>
        <DiagnosisSpinner />
      </DiagnosisSpinnerWrapper>

      <StatusTitle> Procesando imágenes... </StatusTitle>

      <StatusSubtitle> Aguarde mientras nuestra IA analiza los estudios </StatusSubtitle>
    </Container>
  )
}

export default Computing
