import { forwardRef } from 'react'
import { Modal } from '../../../Modal'
import {
  ModalTitle,
  ButtonsWrapper,
  Button
} from './styles'

const RemoveImageModal = forwardRef(({ position, close, confirm }, ref) => {
  return (
    <Modal ref={ref}>
      <ModalTitle> ¿Eliminar imagen {position}? </ModalTitle>

      <ButtonsWrapper>
        <Button onClick={close}> Cancelar </Button>
        <Button highlighted onClick={confirm}> Eliminar </Button>
      </ButtonsWrapper>
    </Modal>
  )
})

export default RemoveImageModal
