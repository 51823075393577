import styled, { css } from 'styled-components'
import { colors } from '../../const'

export const Container = styled.div`
  background-color: ${colors.white};
  border-radius: 6px;
  padding-top: 10%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 4% 2% 8% 2%;
  height: 150px;
  width: 100%;
`

export const ContainerStepperInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  margin-top: 1px;
  max-width: 200px;
`

export const CaseNumberTitle = styled.p`
  color: #0077CC;
  font-family: 'Inter';
  font-weight: 700;
  margin: 2% 0;
`

export const CaseNumberCounter = styled.span`
  color: ${colors.lightBlack};
  display: block;
  font-family: 'Inter';
  font-size: 0.875rem;
  margin-top: 6%;
`

export const StepperWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-grow: 1;
  width: 100%;
`

export const StepperContainer = styled.div`
  width: 100%;
  width: 70%;
  display: flex;
  height: 100%;
`

export const Step = styled.div(
  ({ active, completed, clickeable, text }) => css`
    align-items: center;
    background-color: ${active ? '#0077CC' : colors.white};
    border: 2px solid ${active ? '#0077CC' : completed ? '#0077CC' : '#ABB4C4'};
    border-radius: 50%;
    color: ${active ? colors.white : '#ABB4C4'};
    display: flex;
    font-family: 'Inter';
    font-weight: bold;
    margin:0;
    height: 30px;
    justify-content: center;
    line-height: 21px;
    position: relative;
    width: 30px;
    cursor: ${completed ? (clickeable?'pointer':'not-allowed') : 'default'};

    &::after {
      color: ${active ? '#0077CC' : '#ABB4C4'};
      content: '${text}';
      font-size: 1rem;
      position: absolute;
      text-align: center;
      top: 36px;
      width: 120px;
    }
  `
)

export const Connector = styled.div`
  background-color: #D3DAE6;
  height: 2px;
  margin-top: 14px;
  width: 25%;
  display: flex;
`

export const CancelCase = styled.button`
  background: transparent;
  border: none;
  color: #B4251D;
  cursor: pointer;
  width: 80%;
  display: flex;
  font-family: 'Inter';
  font-size: 1rem;
  font-weight: 500;
  margin: 2% 0;
  padding: 0;
  &:hover {
    text-decoration: underline;
  }
`

export const Icon = styled.img`

`
