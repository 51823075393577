import { PrincipalImage } from "./styles"
import { ImagePercentage } from "../../ImagePercentage"
import { useModal } from "../../../../hooks/useModal"
import ImageFull from "./ImageFullScreenModal"
import { useImageLoading } from "../../../../hooks/useImageLoading"

export const ImageFullScreen= ({whichEye, currentSelectedImage})=>{
    const [modalRef, openModal, closeModal] = useModal()
    const { loading, img } = useImageLoading(whichEye, currentSelectedImage);

    return(
        <>
            <ImageFull image={currentSelectedImage.image} ref={modalRef} />
            <PrincipalImage onClick={openModal} status={img.status} src={img.image} alt='principal-image' />
            <ImagePercentage loading={loading} image={img} />
        </>
    )
}