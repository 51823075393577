import styled, { css } from 'styled-components'
import { colors } from '../../const'

export const Container = styled.header`
  align-items: center;
  background: ${colors.lightBlack};
  box-shadow:
    0px 1px 1px rgba(0, 0, 0, 0.07),
    0px 2px 4px rgba(0, 0, 0, 0.05), 
    0px 5px 10px rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: space-between;
  height: 58px;
  padding: 4px 12px;
  width: 100%;
`

export const MenuSide = styled.div`
  align-items: center;
  display: flex;
  position: relative;
  width: 100%;
`

export const MenuIcon = styled.img`
  cursor: pointer;
`

export const Logo = styled.img`
  cursor: pointer;
  margin: 0 8px;
  transition: 0.3s;
  
  &:hover {
    transform: rotate(-6deg);
  }
`

export const AvatarSide = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  position: relative;
  width: 100px;
`

export const AvatarImage = styled.img`

`

export const AvatarLetter = styled.div`
  align-items: center;
  background-color: ${colors.primary};
  border-radius: 50%;
  color: ${colors.white};
  cursor: pointer;
  display: flex;
  font-family: 'Inter';
  height: 32px;
  justify-content: center;
  width: 32px;
`

export const HamburgerMenu = styled.div(
  ({ show }) => css`
    background-color: ${colors.white};
    border-radius: 6px;
    bottom: -116px;
    box-shadow:
      0px 0.9px 4px -1px rgba(0, 0, 0, 0.08),
      0px 2.6px 8px -1px rgba(0, 0, 0, 0.06),
      0px 5.7px 12px -1px rgba(0, 0, 0, 0.05);
    display: ${show ? 'flex' : 'none'};
    flex-direction: column;
    left: 0;
    max-width: 250px;
    position: absolute;
    width: 100%;
    z-index: 2;
  `
)

export const HamburgerOption = styled.div(
  ({ active }) => css`
    align-items: center;
    box-shadow: inset 0px -1px 0px rgba(211, 218, 230, 0.5);
    color: ${colors.lightBlack};
    display: flex;
    font-family: 'Inter';
    font-size: 0.875rem;
    font-weight: 500;
    height: 32px;
    padding-left: 10%;
    width: 100%;

    &:hover {
      background-color: rgba(0, 109, 228, 0.1);
      cursor: pointer;
    }
  `
)

export const UserMenu = styled.div(
  ({ show }) => css`
    background-color: ${colors.white};
    border-radius: 6px;
    box-shadow:
      0px 0.9px 4px -1px rgba(0, 0, 0, 0.08),
      0px 2.6px 8px -1px rgba(0, 0, 0, 0.06),
      0px 5.7px 12px -1px rgba(0, 0, 0, 0.05);
    display: ${show ? 'flex' : 'none'};
    flex-direction: column;
    font-family: 'Inter';
    padding: 4px;
    position: absolute;
    right: 0;
    top: 52px;
    z-index: 2;
  `
)

export const RoleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-bottom: 6px;
  min-width: 160px;
  width: 100%;
`

export const StatusWrapper = styled.div`
  border-top: 1px solid rgba(30, 30, 30, 0.2);
  display: flex;
  flex-direction: column;
  padding-top: 6px;
`

export const Label = styled.label`
  display: flex;
  justify-content: space-between;
  font-family: 'Inter';
  font-size: 0.875rem;
  margin: 2px 0;
  width: 100%;
`

export const Input = styled.input``
