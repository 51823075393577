import { forwardRef } from 'react'
import { Modal } from '../../Modal'
import { ModalTitle, ButtonsWrapper, Button } from './styles'

export const SessionExpiredModal = forwardRef(({ confirm }, ref) => {
    return (
      <Modal ref={ref}>
        <ModalTitle> Su sesión ha expirado </ModalTitle>
  
        <ButtonsWrapper>
          <Button onClick={confirm}> Iniciar sesión </Button>
        </ButtonsWrapper>
      </Modal>
    )
  })
  
  export default SessionExpiredModal
  