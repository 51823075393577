import { useState, useRef, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { modifyLeftEyeImages, modifyRightEyeImages, modifyLeftEyeCurrentImage, modifyRightEyeCurrentImage } from '../redux/images'
import { useUploadImage } from './NewCase/Service/useUploadImage'
import { useGetQuality } from './NewCase/Service/useGetQuality'
import { useRemoveImage } from './NewCase/Service/useRemoveImage'
import { useRestoreImage } from './useRestoreImage'

const MAX_FILES_LENGTH = 7

export function useImagesSelector ({ whichEye }) {
  const { leftEye, rightEye } = useSelector(state => state.images)
  const { uploadImage } = useUploadImage()
  const { getStatus, getQualityImage } = useGetQuality()
  const { removeImage } = useRemoveImage()
  const { restoreImage } = useRestoreImage()

  const dispatch = useDispatch()
  const PROVISIONAL_ID_IMAGES = {
  }
  const EYE_REFERENCE = {
    left: {
      currentSelectedImage: leftEye.currentImage,
      images: leftEye.images,
      handleChangeImages: modifyLeftEyeImages,
      setCurrentSelectedImage: modifyLeftEyeCurrentImage
    },
    right: {
      currentSelectedImage: rightEye.currentImage,
      images: rightEye.images,
      handleChangeImages: modifyRightEyeImages,
      setCurrentSelectedImage: modifyRightEyeCurrentImage
    }
  }

  const [nextId, setNextId] = useState(1)
  const inputFileRef = useRef(null)

  const resetInputRef = () => {
    inputFileRef.current.value = ''
  }

  const handleOpenFileExplorer = () => {
    inputFileRef.current.click()
  }
  const loadingFiles = (files) => {
    const { images, handleChangeImages } = EYE_REFERENCE[whichEye]
    const alreadyStoredImages = images.filter(file => file.image !== null)
    const imagesSelected = []
    let localNextId = nextId

    for (let i = 0; i < files.length; i++) {
      const file = files[i]
      const image = URL.createObjectURL(file)
      imagesSelected.push({ id: localNextId, image, name: file.name, status: 'loading' })
      PROVISIONAL_ID_IMAGES[file] = localNextId
      localNextId++
    }
    

    let newImages = [...alreadyStoredImages, ...imagesSelected].slice(0, MAX_FILES_LENGTH)

    for (let i = newImages.length; i < MAX_FILES_LENGTH; i++) {
      newImages.push({ id: localNextId, image: null, name: null, status: 'none' })
      localNextId++
    }

    newImages = newImages.map((image, index) => {
      return { ...image, position: index + 1 }
    })

    setNextId(localNextId)
    dispatch(handleChangeImages(newImages))
  } 
  const uploadFiles = async (files) => {
    const { images, handleChangeImages } = EYE_REFERENCE[whichEye];
    const alreadyStoredImages = images.filter(file => file.image !== null);
  
    const imagesSelected = [];
    let localNextId = nextId;
  
    const uploadFileAndSetImage = async (file) => {
        try {
          const response = await uploadImage(file, whichEye);
      
          if (response) {
            await getQualityImage(await response.idImagen);
            const id = response.idImagen;
            const image = URL.createObjectURL(file);
            const name = file.name;
            return { id, image, name};
          }
        } catch (error) {
          console.log(error);
        }
    };

    const updateImagesStorage = (file, id, finalImage, name, status, iluminacion, contraste, enfoque) => {
      const IMAGE_ID = PROVISIONAL_ID_IMAGES[file];
      const img = alreadyStoredImages.find(image => image.id === IMAGE_ID);

      if (img) {
        const newImages = alreadyStoredImages.map(image => {
          if (image.id === IMAGE_ID) {
            return { ...image, id, finalImage, name, status, iluminacion, contraste, enfoque};
          }
          return image;
        });

        dispatch(handleChangeImages(newImages));
      }
    }
    console.log(files)
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const {id, image, name} = await uploadFileAndSetImage(file); 
      const { iluminacion, contraste, enfoque, status } = await getStatus(id);
      imagesSelected.push({ id, image, name, status, iluminacion, contraste, enfoque });
      updateImagesStorage(file, id, image, name, status, iluminacion, contraste, enfoque);
      localNextId++;
    }

    let newImages = [...alreadyStoredImages, ...imagesSelected].slice(0, MAX_FILES_LENGTH)

    for (let i = newImages.length; i < MAX_FILES_LENGTH; i++) {
      newImages.push({ id: localNextId, image: null, name: null, status: 'none' })
      localNextId++
    }

    newImages = newImages.map((image, index) => {
      return { ...image, position: index + 1 }
    })

    setNextId(localNextId)
    dispatch(handleChangeImages(newImages))
  }

  
  const handleUploadImage = (event) => {
    try {
      const { files } = event.target
      if (files.length) {
        const filesArray = [...files]
        loadingFiles(filesArray)
        uploadFiles(filesArray)
      }
    } catch (err) {
      console.log(err)
    } finally {
      resetInputRef()
    }
  }

  const handleRestoreImages = (study, heatMap = false) => {

    let rightEyeImages = []
    let leftEyeImages = []
    let localRightNextId = 1
    let localLeftNextId = 1

    const restore = async(studyImages, heatMap) => {
      for(let i=0; i<studyImages.length; i++){
        const imageId = studyImages[i].idHash
        const {id, eye, image, heatMapImage ,iluminacion, contraste, enfoque, probabilidad, riesgoIA, status} = await restoreImage(imageId, heatMap)
        eye === 0 ? leftEyeImages.push({id, image, heatMapImage, iluminacion, contraste, enfoque, probabilidad, riesgoIA, status}) : rightEyeImages.push({id, image , heatMapImage, iluminacion, contraste, enfoque, probabilidad, riesgoIA, status})
      }

      for (let i = rightEyeImages.length; i < MAX_FILES_LENGTH; i++) {
        rightEyeImages.push({ id: localRightNextId, image: null, name: null, status: 'none' })
        localRightNextId++
      }

      for (let i = leftEyeImages.length; i < MAX_FILES_LENGTH; i++) {
        leftEyeImages.push({ id: localLeftNextId, image: null, name: null, status: 'none' })
        localLeftNextId++
      }

      rightEyeImages = rightEyeImages.map((image, index) => {
        return { ...image, position: index + 1 }
      })

      leftEyeImages = leftEyeImages.map((image, index) => {
        return { ...image, position: index + 1 }
      })
  
      dispatch(modifyRightEyeImages(rightEyeImages))
      dispatch(modifyLeftEyeImages(leftEyeImages))
    }
    restore(study.imagenes, heatMap)
    

  }

  const handleUploadDroppedImages = (files) => {
    try {
      if (files.length) {
        loadingFiles(files)
        uploadFiles(files)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const handleDropImages = (event) => {
    event.preventDefault()

    const files = []
    if (event.dataTransfer.items) {
      for (let i = 0; i < event.dataTransfer.items.length; i++) {
        if (event.dataTransfer.items[i].kind === 'file') {
          const file = event.dataTransfer.items[i].getAsFile()
          files.push(file)
        }
      }
    }
    handleUploadDroppedImages(files)
  }

  const handleDragImages = (event) => {
    event.preventDefault()
  }

  const handleRemoveImage = () => {
    const { images, currentSelectedImage, setCurrentSelectedImage, handleChangeImages } = EYE_REFERENCE[whichEye]
    console.log("Id de la imagen: "+currentSelectedImage.id)
    removeImage(currentSelectedImage.id)

    const currentIndex = images.findIndex(elem => elem.id === currentSelectedImage.id)
    let newUploadedFiles = images.filter(file => file.image !== null)
      .filter(file => file.id !== currentSelectedImage.id)
      .map((image, index) => {
        return { ...image, position: index + 1 }
      })
    const nextCurrentSelectImageIndex = currentIndex === newUploadedFiles.length ? newUploadedFiles.length - 1 : currentIndex
    const newCurrentImageSelected = newUploadedFiles[nextCurrentSelectImageIndex]?.image ? newUploadedFiles[nextCurrentSelectImageIndex] : null

    let localNextId = nextId
    for (let i = newUploadedFiles.length; i < MAX_FILES_LENGTH; i++) {
      newUploadedFiles.push({ id: localNextId, image: null, name: null })
      localNextId++
    }

    newUploadedFiles = newUploadedFiles.map((image, index) => {
      return { ...image, position: index + 1 }
    })

    setNextId(localNextId)
    dispatch(handleChangeImages(newUploadedFiles))
    dispatch(setCurrentSelectedImage(newCurrentImageSelected))
  }

  return {
    inputFileRef,
    handleOpenFileExplorer,
    handleRestoreImages,
    handleUploadImage,
    handleDragImages,
    handleDropImages,
    handleRemoveImage
  }
}

export default useImagesSelector
