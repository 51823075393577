import styled, { css } from 'styled-components'
import { colors } from '../../const'

const getButtonColor = (color) => {
  if (color === 'primary') return colors.white
  if (color === 'secondary') return '#005EC4'
  if (color === 'error') return colors.white
  return '#005EC4'
}

const getButtonBackground = (color) => {
  if (color === 'primary') return '#0071C2'
  if (color === 'secondary') return 'rgba(0, 109, 228, 0.2)'
  if (color === 'error') return '#F93428'
  return colors.white
}

export const Container = styled.button(
  ({ color }) => css`
    align-items: center;
    background-color: ${getButtonBackground(color)};
    border: none;
    border-radius: 6px;
    color: ${getButtonColor(color)};
    cursor: pointer;
    display: flex;
    font-family: 'Inter';
    font-size: 0.875rem;
    font-weight: 500;
    height: 40px;
    justify-content: center;
    margin-top: 20px;
    min-width: 112px;
    padding: 0 12px;
  `
)
