import { URLS } from "../../../const/nav";

const URL = {
    'technical': ``,
    'ophthalmologist': ``,
    'patient': `/pacientes/propio`
}

export const getUserData = async (role, token) => {
    
    try {
        const response = await fetch(`${URLS.BACKEND_URL + URL[role]}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${token}`
            }
        });
        if (response.ok) {
            return await response.json();
        } else {
            console.error('Error en la solicitud:', response.status, response.statusText);
            return null;
        }
    } catch (error) {
        console.error('Error durante la solicitud:', error);
        return null;
    }
}