import { useState, useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { resetEvaluations } from '../../redux/evaluations'
import { resetImages } from '../../redux/images'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'
import { Data, PatientInformation } from '../../components'
import { setInformInfoInEvaluations } from '../../redux/evaluations'
import { USER_STATUS } from '../../const'
import { toggleToNewStatus } from '../../redux/user'
import { Container, ReportWrapper, CaseId } from './styles'
import GenerateReport from '../../components/GenerateReport'
import { useInform } from '../../hooks/Inform/useInform'
import { ReportSended } from './ReportSended'
import { NewReportModal } from '../../components/Modal/CustomModals/NewReportModal'
import { useModal } from '../../hooks/useModal'
import { useUpdateUserEvaluations } from '../../hooks/useUpdateUserEvaluations'

const STUDY_TYPE = {
  0: 'Examen binocular',
  1: 'Examen monocular ojo derecho',
  2: 'Examen monocular ojo izquierdo'
}

export function NewReport () {
  const { user, evaluations } = useSelector(state => state)
  const [reportSended, setReportSended] = useState(false)
  const [modalRef, openModal, closeModal] = useModal()
  const { updateUserEvaluations } = useUpdateUserEvaluations()
  const [modalType, setModalType] = useState('')
  const history = useHistory()
  const dispatch = useDispatch()
  const { uploadReport, updateInform, cancelReport } = useInform()
  const [newData, setNewData] = useState({
    referible: evaluations && evaluations.informInfo && evaluations.informInfo.referible ? evaluations.informInfo.referible : true,
    grado_RD: evaluations && evaluations.informInfo && evaluations.informInfo.grado_RD ? evaluations.informInfo.grado_RD : 0,
    sospecha_MD: evaluations && evaluations.informInfo && evaluations.informInfo.sospecha_MD ? evaluations.informInfo.sospecha_MD : 0,
    otras_patologias: evaluations && evaluations.informInfo && evaluations.informInfo.otras_patologias ? evaluations.informInfo.otras_patologias : false,
    texto_libre: evaluations && evaluations.informInfo && evaluations.informInfo.texto_libre ? evaluations.informInfo.texto_libre : ''
})

  const handleWarningAction = (action) => {
    setModalType(action)
    openModal()
  }

  const modalAction = () => {
    closeModal()
    if(modalType === 'send'){
      handleSendReport()
    } else if(modalType === 'cancel'){
      handleCancelReport()
    }
  }
  const handleSendReport = async () => {
    await updateInform(newData)
    await uploadReport()
    await updateUserEvaluations()
    dispatch(resetEvaluations())
    dispatch(resetImages())
    dispatch(toggleToNewStatus())
    setReportSended(true)
    history.push('/home')
  }

  const handleCancelReport = async () => {
    await cancelReport()
    await updateUserEvaluations()
    dispatch(resetEvaluations())
    dispatch(resetImages())
    dispatch(toggleToNewStatus())
    history.push('/home')
  }

  const handleSaveReport = async () => {
    dispatch(setInformInfoInEvaluations(newData))
    await updateInform(newData)
    history.push('/home')
  }

  if (user.status === USER_STATUS.LOCKED || user.role === 'technical') {
    return <Redirect to='/home' />
  }

  console.log('estudio', evaluations)

  return (
    <Container>

      <NewReportModal ref={modalRef} close={closeModal} modalType={modalType} actionType={modalAction}/>

      {evaluations && <CaseId> {STUDY_TYPE[evaluations.tipo]}/Estudio_{evaluations.id} </CaseId>}
      <PatientInformation study={evaluations} patientData={evaluations.patientInfo}/>

      {reportSended && (
        <ReportSended />
      )}

      {!reportSended && (
        <ReportWrapper>
          <Data newData={newData} dataSetter={setNewData} study={evaluations} patientData={evaluations.patientInfo}/>
          <GenerateReport save={handleSaveReport} warningAction={handleWarningAction} />
          {/* {user.status === USER_STATUS.CONTINUE
            ? <ReportGenerated />
            : <GenerateReport sendReport={handleChangeReportSended} />
          } */}
          
        </ReportWrapper>
      )}
    </Container>
  )
}

export default NewReport
