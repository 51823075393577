import { useRef } from 'react'
import html2canvas from 'html2canvas'
import {
  Container,
  Header,
  HeaderItem,
  HeaderDetailWrapper,
  HeaderDetailRow,
  HeaderField,
  HeaderValue,
} from './styles'

export const Pdf = () => {
  const printRef = useRef(null)

  const downloadVoucher = async () => {
    try {
      const element = printRef.current
      const canvas = await html2canvas(element)

      const data = canvas.toDataURL('image/jpeg')
      const link = document.createElement('a')

      if (typeof link.download === 'string') {
        link.href = data
        link.download = 'comprobante-coinpro.jpeg'

        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      } else {
        window.open(data)
      }
    } catch (err) {
      console.log(err)
    }
  }
  return (
    <>
      <button onClick={downloadVoucher}> click aca para descargar </button>
      <Container ref={printRef}>
        <Header>
          <HeaderItem> retinar </HeaderItem>
          <HeaderItem> INFORME ASISTINDO DE FOTOGRAFÍA DE FONDO DE OJO </HeaderItem>
          <HeaderDetailWrapper>
            <HeaderDetailRow>
              <HeaderField>Paciente:</HeaderField>
              <HeaderValue>Sr. Carloz Gomez</HeaderValue>
            </HeaderDetailRow>
            <HeaderDetailRow>
              <HeaderField>Seguridad Social:</HeaderField>
              <HeaderValue>AFP92334673</HeaderValue>
            </HeaderDetailRow>
            <HeaderDetailRow>
              <HeaderField>Dirección:</HeaderField>
              <HeaderValue style={{textAlign:'right'}}>Av. Siempreviva 123 (Gardey, Tandil, Buenos Aires)</HeaderValue>
            </HeaderDetailRow>
          </HeaderDetailWrapper>
        </Header>
      </Container>
    </>
  )
}

export default Pdf
