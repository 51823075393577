// Actions
const INCREMENT = '@steps/increment'
const DECREMENT = '@steps/decrement'
const COMPLETE = '@steps/complete'
const RESET = '@steps/reset'
const CONTINUE = '@steps/continue'
const NOT_CONTINUE = '@steps/not-continue'

const INITIAL_STATE = {
  current: 1,
  completed: false,
  continue: false,
}

// Reducer
export default function stepsReducer (state = INITIAL_STATE, action) {
  switch (action.type) {
    case INCREMENT:
      return {
        ...state,
        current: state.current + 1
      }
    case DECREMENT:
      return {
        ...state,
        current: state.current - 1
      }
    case COMPLETE:
      return {
        ...state,
        completed: true,
      }
    case RESET:
      return INITIAL_STATE
    case CONTINUE:
      return {
        ...state,
        current: 3,
        continue: true,
      }
    case NOT_CONTINUE:
      return {
        ...state,
        continue: false,
      }
    default:
      return state
  }
}

// Action creators
export function stepIncrement () {
  return { type: INCREMENT }
}

export function stepDecrement () {
  return { type: DECREMENT }
}

export function stepComplete () {
  return { type: COMPLETE }
}

export function stepReset () {
  return { type: RESET }
}

export function stepContinue () {
  return { type: CONTINUE }
}

export function stepNotContinue () {
  return { type: NOT_CONTINUE }
}
