import styled from 'styled-components'
import { colors } from '../../const'
import SearchIcon from '../../assets/icons/search-icon.svg'

const GRID_SPACES_HEADER_BODY = '0.8fr 1fr 1.2fr 1fr 1fr 1fr 1fr'

export const Container = styled.div`
  font-family: 'Inter';
  margin: 0 auto;
  max-width: 1600px;
  padding: 2% 5%;
  width: 100%;
`

export const Header = styled.div`
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  width: 100%;
`

export const TitleWrapper = styled.div`
  align-items: center;
  display: flex;
  margin-top: 2%;
`

export const TitleIcon = styled.img`

`

export const Title = styled.h1`
  font-size: 1.75rem;
  font-weight: 700;
  margin: 0 0 0 20px;
`

export const Subheader = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
`

export const SearchWrapper = styled.form`
  background: ${colors.white};
  border: 1px solid rgba(19, 34, 149, 0.1);
  border-radius: 6px;
  height: 40px;
  max-width: 40%;
  position: relative;
  width: 100%;

  &::before {
    content: url(${SearchIcon});
    left: 10px;
    position: absolute;
    top: 50%;
    transform: translateY(-40%);
  }
`

export const Input = styled.input`
  background: ${colors.white};
  border: none;
  border-radius: 6px;
  color: ${colors.lightBlack};
  height: 100%;
  font-size: 0.875rem;
  line-height: 21px;
  padding-left: 36px;
  width: 100%;

  &::placeholder {
    color: rgba(52, 55, 65, 0.8);
  }

  &:focus {
    &::placeholder {
      color: transparent;
    }
  }
`

export const FilterWrapper = styled.div`
  display: flex;
  gap: 20px;
  justify-content: flex-end;
  width: 100%;
`

export const SelectWrapper = styled.div`
  align-items: center;
  display: flex;
`

export const SelectLabel = styled.label`
  font-family: 'Inter';
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 18px;
`

export const Select = styled.select`
  background-color: transparent;
  border: none;
  color: #0077CC;
  cursor: pointer;
  font-family: 'Inter';
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 18px;
  margin-left: 6px;
  outline: none;
  padding-right: 16px;
`

export const Option = styled.option`
  
`

export const Table = styled.div`
  background-color: #FFF;
  box-shadow:
    0px 1px 4px -1px rgba(0, 0, 0, 0.08),
    0px 3px 8px -1px rgba(0, 0, 0, 0.06),
    0px 6px 12px -1px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  max-width: 1600px;
  padding: 26px 34px;
  width: 100%;
`

export const TableHeader = styled.div`
  display: grid;
  grid-template-columns: ${GRID_SPACES_HEADER_BODY};
  margin-bottom: 12px;
  width: 100%;
`

export const TableHeaderData = styled.div`
  font-family: 'Montserrat';
  font-size: 0.75rem;
  font-weight: 600;
  padding-bottom: 16px;
`

export const TableBody = styled.div`
  display: flex;
  gap: 18px;
  flex-direction: column;
  margin-bottom: 10px;
  width: 100%;
`

export const TableBodyRow = styled.div`
  background-color: #FFF;
  border-radius: 8px;
  box-shadow:
    0px 1px 4px -1px rgba(0, 0, 0, 0.08),
    0px 3px 8px -1px rgba(0, 0, 0, 0.06),
    0px 6px 12px -1px rgba(0, 0, 0, 0.05);
  display: grid;
  grid-template-columns: ${GRID_SPACES_HEADER_BODY};
  padding: 6px 12px;
  width: 100%;
`

export const TableBodyCell = styled.p``

export const Thumbnail = styled.img``

export const IconsWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  gap: 30px;
`

export const Icon = styled.img`
  cursor: pointer;
  height: 18px;
  width: 18px;
`
