// Actions
const EVALUATIONS_SET = '@evaluations/set'
const EVALUATIONS_RESET = '@evaluations/reset'
const SET_PATIENT_INFO_IN_EVALUATIONS = '@evaluations/setPatientInfo'
const SET_INFORM_INFO_IN_EVALUATIONS = '@evaluations/setInformInfo'
const SET_DATA_LOADED = '@evaluations/setDataLoaded'

// Reducer
export default function evaluationsReducer(state = {}, action) {
    switch (action.type) {
      case EVALUATIONS_SET:
        return action.payload;
      case EVALUATIONS_RESET:
        return {};
      case SET_PATIENT_INFO_IN_EVALUATIONS:
        return {...state, patientInfo: action.payload};
      case SET_INFORM_INFO_IN_EVALUATIONS:
        return {...state, informInfo: action.payload};
      case SET_DATA_LOADED:
        return {...state, dataLoaded: true};
      default:
        return state;
    }
  }

// Action creator
export function setEvaluations (evaluations) {
  return { type: EVALUATIONS_SET, payload: evaluations }
}

export function setPatientInfoInEvaluations (patientInfo) {
  return { type: SET_PATIENT_INFO_IN_EVALUATIONS, payload: patientInfo }
}

export const setInformInfoInEvaluations = (informInfo) => {
  return { type: SET_INFORM_INFO_IN_EVALUATIONS, payload: informInfo }
}

export const setDataLoaded = () => {
  return { type: SET_DATA_LOADED }
}

export function resetEvaluations () {
    return { type: EVALUATIONS_RESET }
}