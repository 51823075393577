import { useCompleteEvaluationData } from "../useCompleteEvaluationData"
import { useDispatch, useSelector } from "react-redux"
import { setEvaluations } from "../../redux/evaluations"
import { resetImages } from "../../redux/images"

export const useSetCase = () => {
    const {user} = useSelector(state => state)
    const {completeEvaluationData} = useCompleteEvaluationData()
    const dispatch = useDispatch()

    const setCase = async (singleCase) => {
        dispatch(resetImages())
        if(user.role==='patient'){
            dispatch(setEvaluations(singleCase))
            await completeEvaluationData(singleCase, 'last')
        }
        // Cuando se quiera incorporar la funcion del oftalmologo...
        // if(user.role==='ophthalmologist'){
        //     dispatch(setEvaluations(singleCase))
        //     await completeEvaluationData(singleCase, 'continue')
        // }
    }

    return {setCase}
}
