import { combineReducers, createStore } from 'redux'
import cases from './cases'
import images from './images'
import snackbar from './snackbar'
import steps from './steps'
import user from './user'
import evaluations from './evaluations'

const reducers = combineReducers({
  cases,
  images,
  snackbar,
  steps,
  evaluations,
  user
})

const store = createStore(reducers)

export default store
