import { useState, useEffect } from 'react';
import { URLS } from '../../../const/nav';


export const useLogin = (data) => {
    const [error, setError] = useState(false);


    const login = async (data) => {
        const r = await fetch(`${URLS.BACKEND_URL}/usuarios/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                username: data.userName,
                clave: data.password,
            }),
        });
        if(r.ok) {
            setError(false);
            return await r.json();
        }else{
            setError(true);
            return null;
        }
    }


    return { login, error };
}