import styled from "styled-components";
import { colors } from "../../const";
export const SpinnerContainer = styled.div`
    position : absolute;
    align-items: center;
    display: flex;
    justify-content: center;
    background-color: ${colors.white};
    height: 100%;
    width: 100%;
    border-radius: 10px;
    `;