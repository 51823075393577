import { useEffect, useState } from 'react'
import { NORMAL_IMAGES, IA_IMAGES } from './helpers'
import Layers from '../../../assets/icons/layers.svg'
import ZoomOutDisabled from '../../../assets/icons/zoom-out-disabled.svg'
import ZoomOutEnabled from '../../../assets/icons/zoom-out-enabled.svg'
import FullScreenDisabled from '../../../assets/icons/full-screen-disabled.svg'
import FullScreenEnabled from '../../../assets/icons/full-screen-enabled.svg'
import ZoomInEnabled from '../../../assets/icons/zoom-in-enabled.svg'
import ZoomInDisabled from '../../../assets/icons/zoom-in-disabled.svg'
import { useDispatch, useSelector } from 'react-redux'
import { modifyLeftEyeCurrentImage, modifyRightEyeCurrentImage } from '../../../redux/images'
import { useModal } from '../../../hooks/useModal'
import {
  Container,
  PrincipalImageInfo,
  PrincipalImageText,
  ImagesContainer,
  ThumbnailsContainer,
  ThumbnailCounterWrapper,
  ThumbnailWrapper,
  ThumbnailImage,
  PrincipalImageContainer,
  PrincipalImage,
  ImageContainer,
  FloatingMenu,
  IAToggler,
  TogglerText,
  TogglerIcon,
  RadioWrapper,
  RadioInput,
  RadioLabel,
  StatisticsWrapper,
  StatisticsElement,
  Title,
  StatisticsText,
  StatisticsPercentage,
  PercentageStatisticsContainer,
  PrincipalImageContainerPercentage,
} from './styles'
import { PercentageValues } from '../ImagePercentage/PercentageValues'
import ImageFull from '../ImagesUpload/ImageFullScreen/ImageFullScreenModal'
import { Loading } from '../../Loading'

const IMAGES_SELECT = {
  normal: NORMAL_IMAGES,
  ia1: IA_IMAGES,
  ia2: IA_IMAGES,
}


export function ImagesReceived ({ isVisible, study, whichEye, onlyIAImages, dr, glaucoma }) {
  const { leftEye, rightEye } = useSelector(state => state.images)
  const [imagesToShow, setImagesToShow] = useState([])
  const [fullScreenIcon, setFullScreenIcon] = useState(FullScreenDisabled)
  const [zoomInIcon, setZoomInIcon] = useState(ZoomInDisabled)
  const [zoomOutIcon, setZoomOutIcon] = useState(ZoomOutDisabled)
  const [IAFullScreen, setIAFullScreen] = useState(null)
  const [zoom, setZoom] = useState(1)
  const [position, setPosition] = useState({ x: 0, y: 0 })
  const [dragging, setDragging] = useState(false)
  const [startPosition, setStartPosition] = useState({ x: 0, y: 0 })
  const [modalRef, openModal, closeModal] = useModal()
  const dispatch = useDispatch()
  const EYE_REFERENCE = {
    left: {
      currentImage: leftEye.currentImage,
      setCurrentImage: modifyLeftEyeCurrentImage,
      images: leftEye.images
    },
    right: {
      currentImage: rightEye.currentImage,
      setCurrentImage: modifyRightEyeCurrentImage,
      images: rightEye.images
    },
  }
  const { currentImage: currentSelectedImage, images } = EYE_REFERENCE[whichEye]

  const handleSelectPrincipalImage = (thumbnail) => {
    setZoom(1)
    const { setCurrentImage } = EYE_REFERENCE[whichEye]
    dispatch(setCurrentImage(thumbnail))
  }
  const handleMouseDown = (e) => {
    e.preventDefault();
    if (zoom > 1) {
      setDragging(true);
      setStartPosition({ x: e.clientX, y: e.clientY });
    }
  };

  const handleMouseMove = (e) => {
    if (dragging) {
      const deltaX = e.clientX - startPosition.x;
      const deltaY = e.clientY - startPosition.y;
      setPosition(prevPosition => ({
        x: prevPosition.x + deltaX,
        y: prevPosition.y + deltaY
      }));
      setStartPosition({ x: e.clientX, y: e.clientY });
    }
  };

  const handleMouseUp = () => {
    setDragging(false);
  };

  // useEffect(() => {
  //   const currentTypeId = currentSelectedImage.id
  //   const nextCurrentImage = IMAGES_SELECT[imagesType].find(image => image.id === currentTypeId)

  //   setCurrentSelectImage(nextCurrentImage)
  // }, [imagesType])

  const handleChangeZoom = (amount) => {
    setZoom((prev) => Math.max(1,prev + amount))
  }

  const handleCloseFullScreen = () => {
    setIAFullScreen(null)
    closeModal()
  }

  const handleOpenFullScreen = (IA) => {
    setIAFullScreen(IA)
  }


  useEffect(() => {
    if(zoom===1){
      setPosition({ x: 0, y: 0 })
    }
  }, [zoom])

  useEffect(() => {
    if(IAFullScreen!==null){
      openModal()
    }
  }, [IAFullScreen])

  useEffect(() => {
    handleSelectPrincipalImage(images[0])
    setImagesToShow(images.filter(thumbnail => thumbnail.image))
  }, [images])

  return (
    <Container isVisible={isVisible}>
      <ImageFull control={true} 
                  IA={IAFullScreen} 
                  image={currentSelectedImage && currentSelectedImage.image} 
                  heatmapImage={currentSelectedImage && currentSelectedImage.heatMapImage} 
                  close={handleCloseFullScreen} 
                  ref={modalRef} />

      <PrincipalImageInfo>
        {currentSelectedImage
          ? <PrincipalImageText> {currentSelectedImage.name} </PrincipalImageText>
          : null}
      </PrincipalImageInfo>

      <ImagesContainer>
        <ThumbnailsContainer>
          {imagesToShow.map((thumbnail) => (
            <ThumbnailCounterWrapper key={thumbnail.id}>
              <ThumbnailWrapper
                image={thumbnail.image}
                selected={currentSelectedImage?.position === thumbnail.position}
                onClick={() => handleSelectPrincipalImage(thumbnail)}
              >
                <ThumbnailImage src={thumbnail.image} alt='add-image' />
              </ThumbnailWrapper>
            </ThumbnailCounterWrapper>
          ))}
        </ThumbnailsContainer>
        <PrincipalImageContainerPercentage>

        {imagesToShow.length>0?<PrincipalImageContainer inTheCenter={currentSelectedImage}>
          <FloatingMenu>
            {!onlyIAImages && (
              <IAToggler onClick={()=>handleOpenFullScreen(true)}>
                <TogglerText> IA </TogglerText>
                <TogglerIcon src={Layers} alt='toggle ia'/>
              </IAToggler>
            )}

            <IAToggler active withGap>
              <TogglerIcon onMouseEnter={()=>setZoomOutIcon(ZoomOutEnabled)} 
                            onMouseLeave={()=>setZoomOutIcon(ZoomOutDisabled)} 
                            onClick={()=>handleChangeZoom(-0.1)}
                            src={zoomOutIcon} alt='zoom out'/>
              <TogglerIcon onMouseEnter={()=>setZoomInIcon(ZoomInEnabled)} 
                            onMouseLeave={()=>setZoomInIcon(ZoomInDisabled)} 
                            onClick={()=>handleChangeZoom(0.1)}
                            src={zoomInIcon} alt='zoom in'/>
              <TogglerIcon onMouseEnter={()=>setFullScreenIcon(FullScreenEnabled)} 
                            onMouseLeave={()=>setFullScreenIcon(FullScreenDisabled)} 
                            onClick={()=>handleOpenFullScreen(false)} 
                            src={fullScreenIcon} 
                            alt='full screen'/>
            </IAToggler>
          </FloatingMenu>
          <ImageContainer
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            onMouseLeave={handleMouseUp}
          >
          <PrincipalImage zoom={zoom} x={position.x} y={position.y} src={currentSelectedImage?.image} alt='principal-image' />
          </ImageContainer>
        </PrincipalImageContainer>:<Loading size={100}/>}
        <PercentageStatisticsContainer>

            <Title>
              {imagesToShow.length>0?'Predicción por IA':'Cargando estudios. Espere por favor...'}
            </Title>
            <StatisticsWrapper>
            
            {imagesToShow.length>0 && <StatisticsElement display={dr?1:0}>
              <StatisticsText>
                Riesgo de retinopatía diabética
              </StatisticsText>
              <StatisticsPercentage>
                <PercentageValues values={currentSelectedImage && currentSelectedImage.probabilidad} dxIA={currentSelectedImage && currentSelectedImage.riesgoIA}/>
              </StatisticsPercentage>
            </StatisticsElement>}

            <StatisticsElement display={glaucoma?1:0} >
              <StatisticsText>
                Riesgo de glaucoma
              </StatisticsText>
              <StatisticsPercentage>
                <PercentageValues values='0.2'/>
              </StatisticsPercentage>
            </StatisticsElement>

          </StatisticsWrapper>
        </PercentageStatisticsContainer>
        </PrincipalImageContainerPercentage>
      </ImagesContainer>
    </Container>
  )
}

export default ImagesReceived
