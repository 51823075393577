import { useEffect, useState } from 'react'
import RetinarLogo from '../../assets/images/lightblue_logo.png'
import Lock from '../../assets/icons/lock.png'
import OpenEye from '../../assets/icons/open_eye.svg'
import CloseEye from '../../assets/icons/close_eye.svg'
import { useLogin } from '../../hooks/Login/useValidate'

import {
  OutterContainer,
  InnerContainer,
  LogoWrapper,
  Logo,
  Title,
  FormWrapper,
  InputAndLabelWrapper,
  InputWrapper,
  Input,
  Label,
  LockIcon,
  EyeIconWrapper,
  EyeIcon,
  ForgotPassword,
  Button,
  Link,
  ErrorMessage
} from './styles'
import { useValidate } from '../../hooks/Login/useValidate'

export function Login () {
  const [showPassword, setShowPassword] = useState(false)
  const [data, setData] = useState({
    userName: '',
    password: ''
  })

  const { validate, error } = useValidate()

  const toggleShowPassword = () => {
    setShowPassword(prevState => !prevState)
  }

  const handleSubmit = async event => {
    event.preventDefault()
    await validate(data)
  }

  const changeHandler = event => {
    setData({ ...data, [event.target.name]: event.target.value })
  }

  return (
    <OutterContainer>
      <InnerContainer>
        <LogoWrapper>
          <Link to='/'>
            <Logo src={RetinarLogo} alt='logo' />
          </Link>
        </LogoWrapper>

        <Title> Iniciar sesión </Title>
        <ErrorMessage> {error && 'Usuario o contraseña incorrectos'} </ErrorMessage>
        <FormWrapper onSubmit={handleSubmit}>
          <InputAndLabelWrapper>
            <Label> Usuario </Label>
            <InputWrapper>
              <Input placeholder='Usuario' name='userName' onChange={changeHandler}/>
            </InputWrapper>
          </InputAndLabelWrapper>

          <InputAndLabelWrapper>
            <Label> Contraseña </Label>
            <InputWrapper>
              <LockIcon src={Lock} alt='lock' />
              <Input type={showPassword ? 'text' : 'password'} placeholder='********' autoComplete='off' name='password' onChange={changeHandler}/>
              <EyeIconWrapper onClick={toggleShowPassword}>
                <EyeIcon src={showPassword ? OpenEye : CloseEye} alt='toggle-password' />
              </EyeIconWrapper>
            </InputWrapper>
          </InputAndLabelWrapper>

          <ForgotPassword href='#'>
            Olvidé mi contraseña
          </ForgotPassword>

          <Button>
            Entrar
          </Button>
        </FormWrapper>
      </InnerContainer>
    </OutterContainer>
  )
}

export default Login
