import styled, { css } from 'styled-components'
import { colors, withScrollbarStyled } from '../../../const'
import LockIcon from '../../../assets/icons/lock.png'

export const Container = styled.div(
  ({ isVisible }) => css`
    display: ${isVisible ? 'flex' : 'none'};
    flex-direction: column;
    font-family: 'Inter';
    width: 100%;
  `
)

export const PrincipalImageInfo = styled.div`
  align-items: center;
  border-bottom: 2px solid rgba(211, 218, 230, 0.5);
  display: flex;
  height: 50px;
  justify-content: space-between;
  width: 100%;
`

export const PrincipalImageText = styled.p`
  color: #0077CC;
  font-size: 0.875rem;
  overflow: hidden;
  padding-left: 3%;
  text-overflow: ellipsis;
  white-space: nowrap;x
  width: 90%;
`

export const ImagesContainer = styled.div`
  display: flex;
  height: 100%;
  padding: 2% 0 0;
  width: 100%;
`

export const PrincipalImageContainerPercentage = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`

export const ThumbnailsContainer = styled.ol`
  margin: 0;
  max-height: 450px;
  min-height: 450px;
  overflow-y: auto;
  padding: 0 0 2% 0;
  width: 18%;

  counter-reset: counter-name 0;
  ${withScrollbarStyled}
`

export const ThumbnailCounterWrapper = styled.li(
  () => css`
    margin: 0 auto;
    position: relative;

    &::before {
      color: ${colors.primary};
      content: counter(counter-name);
      counter-increment: counter-name;
      width: 10px;
      height: 10px;
      z-index: 2;
      position: absolute;
    }
  `
)

export const ThumbnailWrapper = styled.div(
  ({ selected }) => css`
    align-items: center;
    background-color: #F5F7FA;
    border: 4px solid ${selected ? '#0077CC' : 'transparent'};
    cursor: pointer;
    display: flex;
    height: 64px;
    justify-content: center;
    margin: 12px auto;
    position: relative;
    width: 64px;
  `
)

export const ThumbnailImage = styled.img`
  max-height: 56px;
  max-width: 100%;
  position: relative;
`
export const NoImageMessage = styled.p`
  color: #ABB4C4;
  font-size: 1rem;
  margin: 0;
  padding: 20px 0;
  text-align: center;
  width: 100%;
`

export const PrincipalImageContainer = styled.div(
  ({ inTheCenter }) => css`
    align-items: ${inTheCenter ? 'center' : 'flex-start'};
    background-color: black;
    min-height: 450px;
    display: flex;
    justify-content: center;
    position: relative;
    width: 100%;
  `
)

export const PercentageStatisticsContainer = styled.div`
  padding-top: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`

export const FloatingMenu = styled.div(
  () => css`
    align-items: center;
    background-color: transparent;
    display: flex;
    gap: 10px;
    left: 0;
    padding: 16px;
    position: absolute;
    top: 0;
  `
)

export const IAToggler = styled.div(
  ({ active, withGap }) => css`
    align-items: center;
    background-color: ${ active ? '#0D90E0' : '#ABB4C4'};
    border-radius: 2px;
    cursor: pointer;
    display: flex;
    height: 38px;
    gap: ${withGap ? '6px' : '0'};
    justify-content: space-evenly;
    left: 20px;
    padding: 6px 8px;
    top: 10px;

    &:hover {
      background-color: #0D90E0;
    }
  `
)

export const TogglerText = styled.p`
  color: white;
  font-family: 'Inter';
  font-weight: 500;
  font-size: 1rem;
  margin: 0 4px;
`

export const TogglerIcon = styled.img`
  margin: 0 4px;
`

export const ImageContainer = styled.div`
  align-items: center;
  display: flex;
  height: 60%;
  justify-content: center;
  width: 80%;
    overflow: hidden;
`
export const PrincipalImage = styled.img.attrs(props => ({
  style: {
    height: '100%',
    maxWidth: '100%',
    width: '100%',
    objectFit: 'contain',
    transform: `scale(${props.zoom}) translate(${props.x}px, ${props.y}px)`,
    cursor: props.zoom > 1 ? 'grab' : 'auto',
    userSelect: 'none'
  },
}))``;

export const RadioWrapper = styled.div`
  background-color: #FFF;
  border-radius: 3px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding: 4px;
  position: absolute;
  top: 60px;
`

export const RadioInput = styled.input`
  
`
export const Title = styled.h1`
  color: ${colors.primary};
  font-size: 1rem;
  font-weight: 700;
  margin: 0;
`

export const StatisticsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  font-family: 'Inter';
  margin-bottom: 10px;
  padding: 12px 0 14px;
  width: 100%;
  justify-content: center;
`

export const StatisticsText = styled.p`
  color: ${colors.lightBlack};
  font-size: 0.875rem;
  font-weight: 500;
  margin: 4px 0;
`

export const StatisticsElement = styled.div(({display})=> css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  display: ${display===0 && `none`}
`
)
export const StatisticsPercentage = styled.div`
  width: 80%;
`

export const RadioLabel = styled.label`
  padding: 4px;
  white-space: nowrap;
`
