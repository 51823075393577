import { useSearchPatientService } from "./Service/useSearchPatientService";
import { useState, useEffect } from "react";

export const useSearchPatient = () => {
    const [error, setError] = useState(false);
    const { response, searchPatient } = useSearchPatientService();
    const [patient] = useState(JSON.parse(sessionStorage.getItem('_patient')));
    const [patientData, setPatientData] = useState(null);
    
    const search = async (id) => {
        if(id){
            await searchPatient(id, true);
        }else{
            await searchPatient(patient);
        }
    };
    
    useEffect(() => {
        const fetchData = async () => {
          try {
            if(response){
              setPatientData(response);
              setError(false);
            }else{
              setError(true)
            }
          } catch (error) {
            console.error("Error al obtener la respuesta:", error);
            setError(true);
          }
        };
      
        fetchData();
      }, [response]);
      
    return {
        patient,
        patientData,
        search,
        error
    };
    };