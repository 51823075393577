import styled, { css } from 'styled-components'
import { colors } from '../../const'

export const Container = styled.footer`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
`

export const LeftFooterSide = styled.div`
  align-items: center;
  color: #5A606B;
  display: flex;
  gap: 4px;
  justify-content: flex-start;
  max-width: 300px;
  width: 100%;
`

export const LeftFooterSideText = styled.p`
  font-size: 0.875rem;
  margin: 0;
`

export const LeftFooterSideSelect = styled.select`
  background-color: transparent;
  border: none;
  color: #5A606B;
  font-size: 0.875rem;
  outline: none;
  padding-right: 4px;
`

export const LeftFooterSideOption = styled.option`
  color: #5A606B;
`

export const RightFooterSide = styled.footer`
  align-items: center;
  display: flex;
  gap: 14px;
  justify-content: center;
  margin-right: 26px;
`

export const RightFooterSideElement = styled.div(
  ({ clickable, active }) => css`
    border-bottom: 2px solid ${active ? '#0077CC' : 'transparent'};
    color: ${active ? '#0077CC' : colors.lightBlack};
    cursor: ${clickable ? 'pointer' : 'auto'};
    font-weight: ${active ? 700 : 500};
  `
)
