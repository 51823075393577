import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useState } from 'react'

export const useImageLoading = (whichEye, image) => {
    const images = useSelector(state => state.images);
    const [loading, setLoading] = useState(true);
    const [img, setImg] = useState(image);
    const eye = {
        left: 'leftEye',
        right: 'rightEye'
    };

    useEffect(() => {
        if(image.status !== 'loading'){
            setLoading(false);
            setImg(image);
        }
        const selectedImage = images[eye[whichEye]].images.find(
            img => img.position === image.position
        );
        
            if (selectedImage) {
                if (selectedImage.status === 'loading') {
                    setLoading(true);
                } else {
                    setLoading(false);
                }
                setImg(selectedImage);
            }
    }, [images]);

    return { loading, img };
}