import { Container } from './styles'

export function Button (props) {
  const { color, size, children, ...rest } = props
  return (
    <Container
      color={color}
      size={size}
      {...rest}
    >
      {children}
    </Container>
  )
}

export default Button
