import styled, { css } from 'styled-components'
import { colors } from '../../const'
import ExternalLinkIcon from '../../assets/icons/external_link.png'
import LockIcon from '../../assets/icons/lock-info.svg'

const getButtonColor = (type) => {
  if (type === 'primary') return colors.white
  if (type === 'secondary') return '#005EC4'
  return '#005EC4'
}

const getButtonBackground = (type) => {
  if (type === 'primary') return colors.primary
  if (type === 'secondary') return 'rgba(0, 109, 228, 0.2)'
  return colors.white
}

export const Container = styled.div`
  background-color: ${colors.white};
  box-shadow:
    0px 0px 1px rgba(0, 0, 0, 0.06),
    0px 0px 2px rgba(0, 0, 0, 0.04),
    0px 0px 5px rgba(0, 0, 0, 0.04),
    0px 0px 17px rgba(0, 0, 0, 0.03);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  font-family: 'Inter';
  justify-content: flex-end;
  padding: 2%;
  width: 55%;
`

export const ButtonsWrapper = styled.footer`
  align-items: center;
  display: flex;
  gap: 20px;
  justify-content: flex-end;
  margin-bottom: 10px;
  margin-top: auto;
  width: 100%;
`

export const Separator = styled.div`
  background-color: rgba(211, 218, 230, 0.5);
  height: 2px;
  margin: 4% 0;
  width: 100%;
`

export const Button = styled.button(
  ({ type }) => css`
    align-items: center;
    background-color: ${getButtonBackground(type)};
    border: none;
    border-radius: 6px;
    color: ${getButtonColor(type)};
    cursor: pointer;
    display: flex;
    font-family: 'Inter';
    font-size: 0.875rem;
    font-weight: 500;
    height: 40px;
    justify-content: center;
    margin-top: 20px;
    min-width: 112px;
    padding: 0 12px;
  `
)
