import { useSelector } from 'react-redux'
import { URLS } from '../../const/nav';
export const usePostStudy = () => {
    const { user } = useSelector(state => state);

    const postStudy = async (data) => {
        return fetch(`${URLS.BACKEND_URL}/estudios/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${user.token}`
            },
            body: JSON.stringify(data)
        })
        .then(response => {
            if (response.ok) {
                const r = response.json();
                return r;
              } else {
                console.error('Error en la solicitud:', response.status, response.statusText);
                return null;
              }
        })
        .catch(error => {
            console.error('Error durante la solicitud:', error);
            return null;
        });
    };
    return { postStudy };
};