import styled, { css } from 'styled-components'
import { colors } from '../../../const'


const setColor = (highlighted, error) => {
  if(error) return colors.error
  if(highlighted) return '#0071C2'
  return colors.lightBlack
}

const buttonColor = (available) => {
  if(available) return colors.primary
  return colors.secondary
}

export const Container = styled.div`
  background-color: ${colors.white};
  box-shadow:
    0px 1px 1px rgba(0, 0, 0, 0.07),
    0px 2px 4px rgba(0, 0, 0, 0.05),
    0px 5px 10px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
  width: 100%;
  flex-direction: column;
`

export const SearchUserTitle = styled.h1`
  border-bottom: 1px solid #D3DAE6;
  color: ${colors.lightBlack};
  font-family: 'Inter';
  font-size: 1.375rem;
  font-weight: 700;
  margin: 0 0 20px 0;
  padding: 20px 20px 20px 30px;
  width: 100%;
`

export const InputWrapper = styled.div`
  border-right: 1px solid #D3DAE6;
  display: flex;
  flex-direction: column;
  margin: 20px 0 20px 30px;
  padding: 0 40px 20px 0;
  width: 30%;
`

export const Label = styled.label(
  ({ highlighted, error }) => css`
    color: ${setColor(highlighted, error)};
    font-family: 'Inter';
    font-size: 0.75rem;
    font-weight: 700;
    margin-bottom: 8px;
  `
)

export const Input = styled.input(({error})=>css`
background-color: #FBFCFD;
border: 1px solid ${error?colors.error:`rgba(19, 34, 149, 0.1)`};
border-bottom-width: 2px;
border-radius: 6px;
font-family: 'Inter';
padding: 10px;
position: relative;
height: 40px;

&:focus {
  outline: none;
  background-color: ${colors.white};
  border-bottom-color: ${error?colors.error:colors.primary};
}`)

export const RadioWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 0 20px 30px;
  width: 100%;
`

export const RadioOptions = styled.div`
  display: flex;
  flex-direction: row;
  height: 40px;
  justify-content: flex-start;
  align-items: center;
  
`
export const ContainterOptions = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 15%;
  align-items: center;
`

export const RadioInput = styled.input`
  margin-right: 5px;
`

export const RadioLabel = styled.label`
  margin-right: 10px;
`

export const ButtonsWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-left: auto;
  margin-right: 30px;
  max-width: 260px;
  width: 50%;
  margin-bottom: 30px;
`

export const TopContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`

export const Button = styled.button(
  ({ highlighted, available }) => css`
    align-items: center;
    background-color: ${highlighted ? buttonColor(available) : colors.secondary};
    border: none;
    border-radius: 6px;
    color: ${highlighted ? colors.white : '#005EC4'};
    cursor: pointer;
    display: flex;
    font-family: 'Inter';
    font-size: 0.875rem;
    font-weight: 500;
    height: 40px;
    justify-content: center;
    max-width: 115px;
    width: 100%;
  `
)
