import styled, { css } from 'styled-components'
import { colors } from '../../../../const'

const statusColor = (status) => {
    if (status === 'error') return '#FF9087'
    return colors.lightBlack
  }
  
  const percentageColor = (value, dxIA) => {
    if(dxIA !== null) return colors.primary
    if (value < 33) return '#FF0505'
    if(value > 33 && value < 66) return '#FFBF00'
    return colors.primary
  }

export const DataPercentageContainer = styled.div(({value, dxIA}) => css`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 2%;
  align-items: center;
  height: 100%;
  width: 100%;
  color: ${percentageColor(value, dxIA)};
`)

export const PrincipalImageStatusPercentage = styled.div(({value, dxIA}) => css`
  border-radius: 6px;
  border: 1px solid ${percentageColor(value, dxIA)};
  height: 50%;
  display: flex;
  justify-content: flex-start;
  width: 70%;
`
)

export const Percentage = styled.div(({value, dxIA}) => css`
  color: black;
  display: flex;
  font-size: 0.875rem;
  font-weight: 600;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: ${value === 100 ? '6px' : '0'};
  border-top-right-radius: ${value === 100 ? '6px' : '0'};
  margin: 0;
  width: ${value}%;
  background-color: ${percentageColor(value, dxIA)};
  transition: width 1s ease-in-out;

`
)

export const ValuePercentage = styled.p(({value}) => css`
  font-size: 0.875rem; 
  font-weight: 600;
  margin: 0;
`)
