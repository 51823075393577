import Papers from '../../assets/icons/papers.png'
import Book from '../../assets/icons/book.png'
import html2canvas from 'html2canvas'
import PersonCircle from '../../assets/icons/person_circle.png'
import Mail from '../../assets/icons/email.png'
import BoldIcon from '../../assets/icons/bold.svg'
import ItalicIcon from '../../assets/icons/italic.svg'
import OrderedListIcon from '../../assets/icons/ordered-list.svg'
import UnorderedListIcon from '../../assets/icons/unordered-list.svg'
import Phone from '../../assets/icons/phone.png'
import { useDateFormatter } from '../../hooks/useDateFormatter'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import DownloadIcon from '../../assets/icons/download-white.svg'
import { PendingReport } from '../Pending'
import {
  Container,
  DataRow,
  LeftContent,
  RightContent,
  Separator,
  Icon,
  LabelAndValueWrapper,
  Label,
  PapersText,
  LabelAndContactContainer,
  ContactWrapper,
  IconAndLabelWrapper,
  Contact,
  ParameterDescription,
  ParametersWrapper,
  ParametersCell,
  ParameterTitle,
  SignatureWrapper,
  Signature,
  DigitalSignatureTitle,
  DigitalSignatureValue,
  ButtonsWrapper,
  Button,
  ButtonIcon,
  DigitalSignatureWrapper,
  ContactIcon,
  LabelAndDescriptionWrapper,
  Description,
  InfoContainer,
  ThirdRowContainer,
  Header,
  Title,
  SubTitle,
  TabsWrapper,
  SelectContainer,
  SelectWrapper,
  SelectLabel,
  SelectTemplate,
  Textarea,
  TextareaFooter,
  EditIconsWrapper,
  EditIcon,
  ReportData
} from './styles'
import { useRef } from 'react'

const DATA = {
  referible: {
    false: 'No',
    true: 'Si'
  },
  grado_RD: {
    0: 'Ausencia de Retinopatía',
    1: 'RD no proliferativa Leve',
    2: 'RD no proliferativa Moderada',
    3: 'RD no proliferativa Severa',
    4: 'RD proliferativa'
  },
  sospecha_MD: {
    0: 'Baja',
    1: 'Moderada',
    2: 'Alta'
  },
  otras_patologias: {
    true: 'Si',
    false: 'No'
  }
}

export function Data ({ newData, dataSetter ,study, patientData}) {
  const { user } = useSelector(state => state)
  const { formatDate } = useDateFormatter()
  const history = useHistory()
  const printRef = useRef(null)

  const downloadPDF = async () => {
    try {
      const element = printRef.current
      const canvas = await html2canvas(element)

      const data = canvas.toDataURL('image/jpeg')
      const link = document.createElement('a')

      if (typeof link.download === 'string') {
        link.href = data
        link.download = 'comprobante-coinpro.jpeg'

        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      } else {
        window.open(data)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const handleChangeData = (e) => {
    dataSetter({
      ...newData, [e.target.name]: e.target.value
    })
  }

  return (
    <Container ref={printRef}>
      <TabsWrapper>
      <Header>
        <Title> Datos del estudio </Title>
      </Header>
      </TabsWrapper>
      <DataRow>
        <LeftContent>
          <Icon src={Papers} alt='papers' />
        </LeftContent>
        <RightContent>
          <LabelAndValueWrapper>
            <Label> Fecha de estudio </Label>
            <InfoContainer>
              <PapersText> {study && formatDate(study.fecha)} </PapersText>
            </InfoContainer>
          </LabelAndValueWrapper>

          <LabelAndValueWrapper>
            <Label> Localidad </Label>
            <InfoContainer>
              <PapersText> {patientData && patientData.localidad} </PapersText>
            </InfoContainer>
          </LabelAndValueWrapper>

          <LabelAndValueWrapper>
            <Label> Domicilio </Label>
            <InfoContainer>
              <PapersText> {patientData && patientData.domicilio} </PapersText>
            </InfoContainer>
          </LabelAndValueWrapper>
        </RightContent>
      </DataRow>

      {user.role === 'technical' && <Separator />}

      {user.role === 'technical' && <DataRow>
        <LeftContent>
          <Icon src={PersonCircle} alt='book' />
        </LeftContent>
        <RightContent>
          <LabelAndContactContainer>
            <Label> Datos de contacto </Label>

            <ContactWrapper>
              <IconAndLabelWrapper>
                <ContactIcon src={Mail} alt='mail' />
                <Label> Mail </Label>
              </IconAndLabelWrapper>

              <Contact> {patientData && patientData.email} </Contact>
            </ContactWrapper>

            <ContactWrapper>
              <IconAndLabelWrapper>
                <ContactIcon src={Phone} alt='phone' />
                <Label> Teléfono  </Label>
              </IconAndLabelWrapper>

              <Contact> {patientData && patientData.telefono} </Contact>
            </ContactWrapper>
          </LabelAndContactContainer>
        </RightContent>
      </DataRow>}

      {study && study.informacionAdicional && <Separator />}

      {study && study.informacionAdicional && 
      <DataRow>
        <LeftContent>
          <Icon src={Book} alt='person-circle' />
        </LeftContent>
        <RightContent>
          <ThirdRowContainer>
            <LabelAndDescriptionWrapper>
              <Label> Información adicional </Label>
              <Description>
                {study && study.informacionAdicional}
              </Description>
            </LabelAndDescriptionWrapper>
          </ThirdRowContainer>
        </RightContent>
      </DataRow>}

      {user.role === 'patient' && 
      (study && study.informes && study.informes.length>0?(<ReportData>
        <Separator />
        <Title> Informe detallado del Retinólogo: </Title>

        <ParametersWrapper>
          <ParametersCell>
            <ParameterTitle>Paciente referible a oftalmólogo/a</ParameterTitle>
            <ParameterDescription>{study && study.informes && DATA.referible[study.informes[0].referible]}</ParameterDescription>
          </ParametersCell>

          <ParametersCell>
            <ParameterTitle>Grado de RD observada</ParameterTitle>
            <ParameterDescription>{study && study.informes && DATA.grado_RD[study.informes[0].grado_RD]}</ParameterDescription>
          </ParametersCell>

          <ParametersCell>
            <ParameterTitle>Sospecha de Edema Macular Diabético</ParameterTitle>
            <ParameterDescription>{study && study.informes && DATA.sospecha_MD[study.informes[0].sospecha_MD]}</ParameterDescription>
          </ParametersCell>

          <ParametersCell>
            <ParameterTitle>Se observan otras patologías concomitantes?</ParameterTitle>
            <ParameterDescription>{study && study.informes && DATA.otras_patologias[study.informes[0].otras_patologias]}</ParameterDescription>
          </ParametersCell>
        </ParametersWrapper>

        <LabelAndDescriptionWrapper>
          <Label>Informe profesional extendido:</Label>
          <Description>
            {study && study.informes && study.informes[0].texto_libre}
          </Description>

          {/* <Label>Resumen para el paciente:</Label>
          <Description>
            <div>
              <span>
                <strong>Diagnostico:</strong> Retinopatia Diabetica Proliferativa con riesgo parcial de Edema Macular
              </span>
            </div>
          </Description> */}
        </LabelAndDescriptionWrapper>

        <ButtonsWrapper>
          <Button type='primary' onClick={downloadPDF}>
            <ButtonIcon src={DownloadIcon} alt='download' />
            Descargar PDF
          </Button>
        </ButtonsWrapper>
      </ReportData>):(<PendingReport/>))}

      {user.role === 'ophthalmologist' && <ReportData> 
        <Separator />
          <SubTitle> Diagnostico: </SubTitle>

          <SelectContainer>
            <SelectWrapper>
              <SelectLabel> Paciente referible a oftalmólogo/a </SelectLabel>
              <SelectTemplate name='referible' value={newData.referible} onChange={handleChangeData}>
                <option value={true}>Si</option>
                {!study.resumen_DX_IA && (
                  <>
                    <option value={false}>No</option>
                  </>
                )}
              </SelectTemplate>
            </SelectWrapper>

            <SelectWrapper>
              <SelectLabel> Grado de RD observada </SelectLabel>
              <SelectTemplate name='grado_RD' value={newData.grado_RD} onChange={handleChangeData}>
                <option value={0}>Ausencia de Retinopatía</option>
                <option value={1}>RD no proliferativa Leve</option>
                <option value={2}>RD no proliferativa Moderada</option>
                <option value={3}>RD no proliferativa Severa</option>
                <option value={4}>RD proliferativa</option>
              </SelectTemplate>
            </SelectWrapper>

            <SelectWrapper>
              <SelectLabel> Sospecha de Edema Macular Diabético </SelectLabel>
              <SelectTemplate name='sospecha_MD' value={newData.sospecha_MD} onChange={handleChangeData}>
                <option value={0}>Baja</option>
                <option value={1}>Moderada</option>
                <option value={2}>Alta</option>
              </SelectTemplate>
            </SelectWrapper>

            <SelectWrapper>
              <SelectLabel> Se observan otras patologías concomitantes? </SelectLabel>
              <SelectTemplate name='otras_patologias' value={newData.otras_patologias} onChange={handleChangeData}>
                <option value={true}>Si</option>
                <option value={false}>No</option>
              </SelectTemplate>
            </SelectWrapper>
          </SelectContainer>
          <Separator />
          <SubTitle> Observaciones: </SubTitle>
          <Textarea name='texto_libre' value={newData.texto_libre} onChange={handleChangeData} rows='4' />

          <TextareaFooter>
            <EditIconsWrapper>
              <EditIcon src={BoldIcon} alt='bold edit'/>
              <EditIcon src={ItalicIcon} alt='italic edit'/>
            </EditIconsWrapper>

            <EditIconsWrapper>
              <EditIcon src={OrderedListIcon} alt='ordered list edit'/>
              <EditIcon src={UnorderedListIcon} alt='unordered list edit'/>
            </EditIconsWrapper>
          </TextareaFooter>
        </ReportData>}
    </Container>

  )
}

export default Data
