import { useSelector } from "react-redux";
import { URLS } from "../../../const/nav";

export const useDxStudy = () => {
    const { user } = useSelector(state => state);
    const dxStudy = async (id) => {
        try{
            const r = await fetch(`${URLS.BACKEND_URL}/estudios/diagnosticar/${id}`, {
                                    method: 'PUT',
                                    headers: {
                                        'Content-Type': 'application/json',
                                        'Authorization': `${user.token}`
                                    },
                                })
            if (r.ok) {
                return await r.json();
            }
        }catch(error){
            console.error("Error al obtener la respuesta:", error);
        }
    }

    return { dxStudy };
}