import { forwardRef } from 'react'
import {Modal} from '../../../../Modal'
import { useState, useEffect } from 'react'
import FullScreenDisabled from '../../../../../assets/icons/full-screen-disabled.svg'
import FullScreenEnabled from '../../../../../assets/icons/full-screen-enabled.svg'
import ZoomInDisabled from '../../../../../assets/icons/zoom-in-disabled.svg'
import ZoomInEnabled from '../../../../../assets/icons/zoom-in-enabled.svg'
import ZoomOutDisabled from '../../../../../assets/icons/zoom-out-disabled.svg'
import ZoomOutEnabled from '../../../../../assets/icons/zoom-out-enabled.svg'
import Layers from '../../../../../assets/icons/layers.svg'
import {
    ImageContainer,
    Image,
    FloatingMenu,
    IAToggler,
    TogglerIcon,
    TogglerText,
    RadioWrapper,
    RadioInput,
    RadioLabel
} from './styles'

const ImageFull = forwardRef(({ image, heatmapImage, control=false, IA, close}, ref) => {
  const [fullScreenIcon, setFullScreenIcon] = useState(FullScreenDisabled)
  const [zoomInIcon, setZoomInIcon] = useState(ZoomInDisabled)
  const [currentImage, setCurrentImage] = useState(image)
  const [zoomOutIcon, setZoomOutIcon] = useState(ZoomOutDisabled)
  const [isIAPanelOpen, setIsIAPanelOpen] = useState(false)
  const [imagesType, setImagesType] = useState('normal')
  const [zoom, setZoom] = useState(1)
  const [position, setPosition] = useState({ x: 0, y: 0 })
  const [dragging, setDragging] = useState(false)
  const [startPosition, setStartPosition] = useState({ x: 0, y: 0 })

  const handleChangeZoom = (amount) => {
    setZoom((prev) => Math.max(1,prev + amount))
  }

  const handleMouseDown = (e) => {
    e.preventDefault();
    if (zoom > 1) {
      setDragging(true);
      setStartPosition({ x: e.clientX, y: e.clientY });
    }
  };

  const handleMouseMove = (e) => {
    if (dragging) {
      const deltaX = e.clientX - startPosition.x;
      const deltaY = e.clientY - startPosition.y;
      setPosition(prevPosition => ({
        x: prevPosition.x + deltaX,
        y: prevPosition.y + deltaY
      }));
      setStartPosition({ x: e.clientX, y: e.clientY });
    }
  };

  const handleMouseUp = () => {
    setDragging(false);
  };

  const handleCloseModal = () => {
    close()
    setFullScreenIcon(FullScreenDisabled)
    setImagesType('normal')
    setZoom(1)
    setIsIAPanelOpen(false)
  }

  const handleToggleIAPannel = () => {
    setIsIAPanelOpen(!isIAPanelOpen)
  }

  const handleChangeImageType = (event) => {
    setImagesType(event.target.value)
    setIsIAPanelOpen(false)
  }

  useEffect(() => {
    if(zoom===1){
      setPosition({ x: 0, y: 0 })
    }
  }, [zoom])

  useEffect(() => {
    if(heatmapImage){
      if(imagesType === 'normal'){
        setCurrentImage(heatmapImage.NHM)
      }else if(imagesType === 'ia1'){
        setCurrentImage(heatmapImage.HM)
      }
    }
  }, [imagesType])
  
  useEffect(() => {
    if(IA){
      if(imagesType === 'normal'){
        setCurrentImage(heatmapImage.NHM)
      }else if(imagesType === 'ia1'){
        setCurrentImage(heatmapImage.HM)
      }
    }else{
      setCurrentImage(image)
    }
  }
  , [IA])

  useEffect(() => {
    setCurrentImage(image)
  }, [image])
  
  return (
    <Modal ref={ref} focus={control} imageControl={control} close={handleCloseModal}>
        <ImageContainer
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseUp}
        >
            <Image src={currentImage} zoom={zoom} x={position.x} y={position.y} alt='principal-image' />
        </ImageContainer>
        {control &&(
          <FloatingMenu>



          <IAToggler active withGap>
            {IA && <TogglerIcon onClick={handleToggleIAPannel} src={Layers} alt='toggle ia'/>}
            {isIAPanelOpen && (
              <RadioWrapper>
                {/* <RadioLabel>
                  <RadioInput type='radio' name='iatype' value='ia2' checked={imagesType === 'ia2'} onChange={handleChangeImageType} />
                  Riesgo de glaucoma
                </RadioLabel> */}
                <RadioLabel>
                  <RadioInput type='radio' name='iatype' value='normal' checked={imagesType === 'normal'} onChange={handleChangeImageType} />
                  Oculta todo
                </RadioLabel>

                <RadioLabel>
                  <RadioInput type='radio' name='iatype' value='ia1' checked={imagesType === 'ia1'} onChange={handleChangeImageType} />
                  Riesgo de retinopatía diabética 
                </RadioLabel>

              </RadioWrapper>
            )}
            <TogglerIcon onMouseEnter={()=>setZoomOutIcon(ZoomOutEnabled)} 
                          onMouseLeave={()=>setZoomOutIcon(ZoomOutDisabled)} 
                          onClick={()=>handleChangeZoom(-0.1)}
                          src={zoomOutIcon} alt='zoom out'/>
            <TogglerIcon onMouseEnter={()=>setZoomInIcon(ZoomInEnabled)} 
                          onMouseLeave={()=>setZoomInIcon(ZoomInDisabled)} 
                          onClick={()=>handleChangeZoom(0.1)}
                          src={zoomInIcon} alt='zoom in'/>
            <TogglerIcon onMouseEnter={()=>setFullScreenIcon(FullScreenEnabled)} 
                          onMouseLeave={()=>setFullScreenIcon(FullScreenDisabled)} 
                          onClick={handleCloseModal} 
                          src={fullScreenIcon} 
                          alt='full screen'/>
          </IAToggler>
          </FloatingMenu>)}
    </Modal>
  )
})

export default ImageFull