import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1.5%;
  width: 100%;
`

export const CaseInformation = styled.section`
  display: flex;
  justify-content: space-between;
  margin-top: 2%;
  gap: 2%;
  width: 100%;
`
