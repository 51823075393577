import { useState, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { toggleToTechnicalUser, toggleToPatientUser, toggleToOphthalmologistUser, toggleToNewStatus, toggleToContinueStatus, toggleToLockedStatus, toggleToUnlockStatus, toggleToAdminUser } from '../../redux/user'
import Hamburger from '../../assets/icons/hamburger.png'
import RetinarLogo from '../../assets/images/lightblue_logo_small.svg'
import {
  Container,
  MenuSide,
  AvatarSide,
  MenuIcon,
  Logo,
  AvatarLetter,
  HamburgerMenu,
  HamburgerOption,
  UserMenu,
  RoleWrapper,
  StatusWrapper,
  Label,
  Input,
} from './styles'

export function Header () {
  const { user } = useSelector(state => state)
  const dispatch = useDispatch()
  const hamburguerMenuRef = useRef(null)
  const userRef = useRef(null)
  const [showMenu, setShowMenu] = useState(false)
  const [showUser, setShowUser] = useState(false)
  const history = useHistory()

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)

    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [])

  const handleClickOutside = (event) => {
    if (hamburguerMenuRef && !hamburguerMenuRef.current.contains(event.target)) {
      closeMenu()
    }
  }
  
  const toggleShowMenu = () => {
    setShowMenu(prevState => !prevState)
  }

  const toggleShowUser = () => {
    setShowUser(prevState => !prevState)
  }

  const closeMenu = () => {
    setShowMenu(false)
  }

  const handleGoHome = () => {
    closeMenu()
    history.push('/home')
  }

  const handleGoEvaluations = () => {
    closeMenu()
    history.push('/evaluations')
  }

  const handleGoNewCase = () => {
    closeMenu()
    history.push('/new-case')
  }

  const handleLogOut = () => {
    sessionStorage.clear()
    closeMenu()
    history.push('/login')
  }

  const handleChangeRole = (event) => {
    const selectedRole = event.target.value

    if (selectedRole === 'technical') {
      dispatch(toggleToTechnicalUser())
      const userToStore = { role: 'technical', status: null}
      sessionStorage.setItem('_user', JSON.stringify(userToStore))
    }
    if (selectedRole === 'ophthalmologist') {
      dispatch(toggleToOphthalmologistUser())
      const userToStore = { role: 'ophthalmologist', status: 'new'}
      sessionStorage.setItem('_user', JSON.stringify(userToStore))
    }
    if (selectedRole === 'patient') {
      dispatch(toggleToPatientUser())
      const userToStore = { role: 'patient', status: null}
      sessionStorage.setItem('_user', JSON.stringify(userToStore))
    }
    if (selectedRole === 'admin') {
      dispatch(toggleToAdminUser())
      const userToStore = { role: 'admin', status: null}
      sessionStorage.setItem('_user', JSON.stringify(userToStore))
    }
    history.push('/home')
  }

  const handleChangeOphthalmologistStatus = (event) => {
    const { value } = event.target
    const DISPATCH_OPTIONS = {
      new: toggleToNewStatus,
      continue: toggleToContinueStatus,
      locked: toggleToLockedStatus,
      unlock: toggleToUnlockStatus,
    }

    const userAction = DISPATCH_OPTIONS[value]
    dispatch(userAction())
    const userToStore = { role: 'ophthalmologist', status: value }
    sessionStorage.setItem('_user', JSON.stringify(userToStore))
    history.push('/home')
  }

  return (
    <Container>
      <MenuSide>
        <HamburgerMenu ref={hamburguerMenuRef} show={showMenu}>
          <HamburgerOption onClick={handleGoHome}> Inicio </HamburgerOption>
          <HamburgerOption onClick={handleGoEvaluations}> Listado de evaluaciones </HamburgerOption>
          {/* <HamburgerOption onClick={handleGoNewCase}> Nuevo estudio </HamburgerOption> */}
          <HamburgerOption onClick={handleLogOut}> Cerrar sesión </HamburgerOption>
        </HamburgerMenu>

        <MenuIcon src={Hamburger} alt='menu' onClick={toggleShowMenu} />
        <Logo src={RetinarLogo} onClick={handleGoHome} alt='logo' />
      </MenuSide>

      <AvatarSide>
        <UserMenu ref={userRef} show={showUser}>
          <RoleWrapper>
            <Label>
              Técnico
              <Input name='role' onChange={handleChangeRole} checked={user.role === 'technical'} type='radio' value='technical'/>
            </Label>
            <Label>
              Oftalmólogo
              <Input name='role' onChange={handleChangeRole} checked={user.role === 'ophthalmologist'} type='radio' value='ophthalmologist'/>
            </Label>
            <Label>
              Paciente
              <Input name='role' onChange={handleChangeRole} checked={user.role === 'patient'} type='radio' value='patient'/>
            </Label>
            <Label>
              Admin
              <Input name='role' onChange={handleChangeRole} checked={user.role === 'admin'} type='radio' value='admin'/>
            </Label>
          </RoleWrapper>
          {user.role === 'ophthalmologist' && (
            <StatusWrapper>
              <Label>
                Nuevo estudio
                <Input name='status' onChange={handleChangeOphthalmologistStatus} checked={user.status === 'new'} type='radio' value='new'/>
              </Label>
              <Label>
                Continuar estudio
                <Input name='status' onChange={handleChangeOphthalmologistStatus} checked={user.status === 'continue'} type='radio' value='continue'/>
              </Label>
              <Label>
                Bloqueado
                <Input name='status' onChange={handleChangeOphthalmologistStatus} checked={user.status === 'locked'} type='radio' value='locked'/>
              </Label>
              <Label>
                Desbloquear
                <Input name='status' onChange={handleChangeOphthalmologistStatus} checked={user.status === 'unlock'} type='radio' value='unlock'/>
              </Label>

            </StatusWrapper>
          )}
        </UserMenu>
        {/* Cuando se quiera habilitar la funcion de desplegar menu desde usuario: quitar comentario linea de abajo */}
        {/* <AvatarLetter onClick={toggleShowUser}> */}
        <AvatarLetter>
          {user.role.charAt(0).toUpperCase()}
        </AvatarLetter>
      </AvatarSide>
    </Container>
  )
}

export default Header
