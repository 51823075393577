import { useEffect, useState } from "react";
import { DATA } from "./helpers";
export const useRequiered = (phase) => {
    const [isValid, setIsValid] = useState(null)
    const [buttonAvailable, setButtonAvailable] = useState(null)
    const [patientData, setPatientData] = useState(null)
    const [dataError, setDataError] = useState(DATA[phase])

    useEffect(() => {
      if(phase){
        setDataError(DATA[phase]);
        setIsValid(null); 
        setButtonAvailable(null)
      }
    }, [phase]);

    const checkRequired = (data, noSocialSecurity, button=false) => {
      console.log("data",patientData)
        setIsValid(!button)
        setButtonAvailable(button)
        Object.keys(data).forEach(key => {
          if (data[key] === '' && (patientData?patientData[key] === null || '':true)) {
            !button && setDataError(prevDataError => ({ ...prevDataError, [key]: true }));
            if(phase === 'StepOne') !button? setIsValid(false) : setButtonAvailable(false);
            if(phase === 'PhaseOne' && (!(key==='codigoAfiliado' && noSocialSecurity) && !(key==='departamento' || key==='piso'))) !button? setIsValid(false) : setButtonAvailable(false);
            if(phase === 'PhaseTwo' && !(key==='informacionAdicional')) !button? setIsValid(false) : setButtonAvailable(false);
          }else{
            !button && setDataError(prevDataError => ({ ...prevDataError, [key]: false }))
          }
        });
      };
    
    return {isValid, dataError, setPatientData, checkRequired, buttonAvailable}
    
}