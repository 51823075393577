import { useDispatch } from "react-redux"
import { setPatientInfoInEvaluations, setInformInfoInEvaluations } from "../redux/evaluations"
import { useSearchPatient } from "./NewCase/useSearchPatient"
import useImagesSelector from "./useImagesSelector"
import { useEffect } from "react"
import { useGetInformFromStudy } from "./Inform/useGetInformFromStudy"

export const useCompleteEvaluationData = () => {
    const dispatch = useDispatch()
    const { patientData, search } = useSearchPatient()
    const {inform, getInformFromStudy} = useGetInformFromStudy()
    const {handleRestoreImages} = useImagesSelector('right')


    const completeEvaluationData = async (evaluation, action) => {
        handleRestoreImages(evaluation, true)
        if(action !='last'){
            await search(evaluation.paciente_id)
            if(action != 'new'){
                await getInformFromStudy(evaluation.informes[0].id)
            }
        }
    }

    useEffect(() => {
        if(inform){
            dispatch(setInformInfoInEvaluations(inform))
        }
    }, [inform])

    useEffect(() => {
        if(patientData){
            dispatch(setPatientInfoInEvaluations(patientData))
        }
    }, [patientData])

    return {completeEvaluationData}
}