import { useRef } from 'react'

export function useModal () {
  const ref = useRef(null)

  const handleOpen = () => {
      ref.current.show()
  }

  const handleClose = () => {
    ref.current.hide()
  }

  return [ref, handleOpen, handleClose]
}
