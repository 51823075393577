import {
  Container,
  LeftFooterSide,
  LeftFooterSideText,
  LeftFooterSideSelect,
  LeftFooterSideOption,
  RightFooterSide,
  RightFooterSideElement,
} from './styles'

export const PaginationFooter = ({ currentPage, listOfPages, handleChangePage , handleChangeRowsPerPage}) => {
  return (
    <Container>
      <LeftFooterSide>
        <LeftFooterSideText> Filas por página: </LeftFooterSideText>
        <LeftFooterSideSelect defaultValue='10' onChange={handleChangeRowsPerPage}>
          <LeftFooterSideOption value='10' > 10 </LeftFooterSideOption>
          <LeftFooterSideOption value='25'> 25 </LeftFooterSideOption>
          <LeftFooterSideOption value='50'> 50 </LeftFooterSideOption>
        </LeftFooterSideSelect>
      </LeftFooterSide>

      <RightFooterSide>
        <RightFooterSideElement> {'<'} </RightFooterSideElement>
        {listOfPages.map(page => (
          <RightFooterSideElement
            key={page}
            clickable
            active={page === currentPage}
            onClick={() => handleChangePage(page)}
          >
            {page}
          </RightFooterSideElement>
        ))}
        <RightFooterSideElement> {'>'} </RightFooterSideElement>
      </RightFooterSide>
    </Container>
  )
}

export default PaginationFooter
