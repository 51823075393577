import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import OpenEye from '../../assets/icons/tab_open_eye.png'
import { setReferableCases, setNonReferableCases } from '../../redux/cases'
import OpenEyeUnselected from '../../assets/icons/tap_open_eye_2.png'
import ClosedEye from '../../assets/icons/tab_closed_eye.png'
import ImagesUpload from './ImagesUpload'
import ImagesReceived from './ImagesReceived'
import { useHistory } from 'react-router-dom'
import { stepNotContinue } from '../../redux/steps'
import { useDxStudy } from '../../hooks/NewCase/Service/useDxStudy'
import { resetImages } from '../../redux/images'
import CancelCaseModal from '../Stepper/CancelCaseModal'
import { useModal } from '../../hooks/useModal'
import {
  Container,
  TabsWrapper,
  Tab,
  TabText,
  Icon,
  ButtonsWrapper,
  Button
} from './styles'

export function IAQualityControl ({ study, nextStep }) {
  const { images } = useSelector(state => state)
  const [modalRef, openModal, closeModal] = useModal()
  const availableTab = (which) => {
    if(!study){
      return true
    }
    if(study.tipo === 0) return true

    if (which === 1) {
      return study.tipo === 1
    } else {
      return study.tipo === 2
    }
  }
  const TABS = [
    { id: 0, text: 'Imágenes ojo derecho', available: availableTab(1) },
    { id: 1, text: 'Imágenes ojo izquierdo', available: availableTab(2) },
  ]

  console.log('imagenes', images)
  const noImages = () => {
    const left = images.leftEye.images[0].image
    const right = images.rightEye.images[0].image

    if(study.tipo === 0)
      return left === null || right === null
    if(study.tipo === 1)
      return right === null
    if(study.tipo === 2)
      return left === null
  }

  const { user } = useSelector(state => state)
  const [selectedTab, setSelectedTab] = useState(TABS[0].available ? TABS[0] : TABS[1])
  const [emptyImages, setEmptyImages] = useState(true)
  const distpatch = useDispatch()
  const { dxStudy } = useDxStudy()

  useEffect(() => {
    if(noImages())
      setEmptyImages(true)
    else
      setEmptyImages(false)
  }, [images])

  const evaluate = async () => {
    dxStudy(study.id).then((response) => {
          console.log(response)
          if(response.resumen_DX_IA){
            distpatch(setReferableCases())
          }else{
            distpatch(setNonReferableCases())
          }
        distpatch(resetImages())
      })
  }

  const handleSelectTab = (id) => {
    const clickedTab = TABS.find(elem => elem.id === id)
    setSelectedTab(clickedTab)
  }


  const handleDragOver = (event, tab) => {
    event.preventDefault()
    if (tab.id !== selectedTab.id) {
      setSelectedTab(tab)
    }
  }
  
  const handlePressEvaluateButton = () => {
    if(emptyImages) return
    distpatch(stepNotContinue())
    nextStep()
    evaluate()
  }

  return (
    
    <Container role = {user.role}>
        <CancelCaseModal
          ref={modalRef}
          close={closeModal}
        />
        <TabsWrapper>
        {TABS.map(tab => (
          <Tab
            key={tab.id}
            active={tab.id === selectedTab.id}
            available={tab.available}
            onClick={tab.available ? () => handleSelectTab(tab.id):null}
            onDragOver={event => handleDragOver(event, tab)}
          >
            <TabText> {tab.text} </TabText>
            {tab.available?<Icon src={selectedTab.id===tab.id? OpenEye:OpenEyeUnselected} alt='icon' />:
            <Icon src={ClosedEye} alt='icon' />}
          </Tab>
        ))}
      </TabsWrapper>
      
      
      {user.role === 'technical' && (
        <>
          <ImagesUpload study = {study} whichEye={'right'} isVisible={selectedTab.id === 0} />
          <ImagesUpload study = {study} whichEye={'left'} isVisible={selectedTab.id === 1} />
          <ButtonsWrapper>
            <Button type='secondary' onClick={openModal}> Cancelar </Button>
            <Button
              type='primary'
              disable={emptyImages}
              onClick={handlePressEvaluateButton}
            >
              Realizar diagnóstico
            </Button>
          </ButtonsWrapper>
        </>
      )}

      {['ophthalmologist', 'patient'].includes(user.role) && (
        <>
          <ImagesReceived study={study} whichEye={'right'} onlyIAImages={user.role === 'patient'} isVisible={selectedTab.id === 0} dr={true} glaucoma={false} />
          <ImagesReceived study={study} whichEye={'left'} onlyIAImages={user.role === 'patient'} isVisible={selectedTab.id === 1} dr={true} glaucoma={false} />
        </>
      )}

    </Container>
  )
}

export default IAQualityControl
