import { useSelector } from 'react-redux'
import List from '../../assets/images/evaluations.svg'
import New from '../../assets/images/new.svg'
import Continue from '../../assets/images/continue.svg'
import Locked from '../../assets/images/locked.svg'
import Unlock from '../../assets/images/unlock.svg'
import { LoadingScreen } from '../LoadingScreen'
import { USER_STATUS } from '../../const'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'
import { useDispatch } from 'react-redux'
import { toggleToContinueStatus } from '../../redux/user'
import { setEvaluations, setDataLoaded, setInformInfoInEvaluations } from '../../redux/evaluations'
import { useGetInform } from '../../hooks/Login/useGetInform'
import {useCompleteEvaluationData} from '../../hooks/useCompleteEvaluationData'
import { NoStudyModal } from '../Modal/CustomModals/NoStudyModal'
import { useState } from 'react'
import { useModal } from '../../hooks/useModal'
import { useUpdateUserEvaluations } from '../../hooks/useUpdateUserEvaluations'
import { useGetInformFromStudy } from '../../hooks/Inform/useGetInformFromStudy'
import {
  Container,
  Button,
  IconWrapper,
  IconOption,
  Link,
  DummyLink,
  DummyButton,
  FloatingMessage,
  FloatingMessageWithIcon,
  LinkContainer,
} from './styles'
import { useEffect } from 'react'

const CASE_ICON = {
  new: New,
  patient: List,
  continue: Continue,
  locked: Locked,
  unlock: Unlock,
}

const DEFAULT_CASE_ICON = New

const CASE_TEXT = {
  new: 'Evaluar nuevo estudio',
  patient: 'Ver ultimo estudio',
  continue: 'Continuar estudio',
  locked: 'Tiempo restante: 48:00hs',
  unlock: 'Habilitar módulo de informes',
}

const DEFAULT_CASE_TEXT = 'Nuevo estudio'

export function UserOption ({ type, highlighted, children }) {
  const { data, action, getInform } = useGetInform()
  const { updateUserEvaluations } = useUpdateUserEvaluations()
  const {inform, getInformFromStudy} = useGetInformFromStudy()
  const [loading, setLoading] = useState(false)
  const [dataLoaded, setDataLoaded] = useState(false)
  const [modalRef, openModal, closeModal] = useModal()
  const history = useHistory()
  const { completeEvaluationData } = useCompleteEvaluationData()
  const { user } = useSelector(state => state)
  const dispatch = useDispatch()
  const CaseIcon = CASE_ICON[user.status] || DEFAULT_CASE_ICON
  const caseText = CASE_TEXT[user.status] || DEFAULT_CASE_TEXT

  useEffect(() => {
    const load = async () => {
      await updateUserEvaluations()
      if(user.role === 'technical'){
        user && user.evaluations && user.evaluations.map(evaluation => {
            if(evaluation.estado === 'incompleto'){
              dispatch(toggleToContinueStatus())
              dispatch(setEvaluations(evaluation))
            }
          })
      }
      if(user.role === 'ophthalmologist'){
        user && user.evaluations && user.evaluations.map(evaluation => {
          if(evaluation.estado === 'informando'){
            dispatch(toggleToContinueStatus())
            getInformFromStudy(evaluation.informes[evaluation.informes.length - 1].id)
            dispatch(setEvaluations(evaluation))
          }
        })
      }
    }
    load()
  }, [])

  const getLastEvaluation = (evaluations) => {
    let last = evaluations[0]
    let lastId = 0
    for(let i = 0; i < evaluations.length; i++){
      if(evaluations[i].estado === 'informado'){
        if(lastId < evaluations[i].id){
          lastId = evaluations[i].id
          last = evaluations[i]
        }
      }
    }
    return last
  }

  useEffect(() => {
    if(inform){
      dispatch(setInformInfoInEvaluations(inform))
    }
  }, [inform])

  const handleSetEvaluation = async () => {
    setLoading(true)
    if(user.role === 'technical'){
      setLoading(false)
      history.push('/new-case')
    }
    if(user.role === 'patient'){
      const fetchData = async () => {
        try{
          const fetchData = async () => {
            await getInform(user.token, 'last', getLastEvaluation(user.evaluations))
          }
          fetchData()
        }
        catch(error){
          setLoading(false)
        }
      }
      await fetchData()
    }
    if(user.role === 'ophthalmologist'){
      const fetchData = async () => {
        try{
          const fetchData = async () => {
            await getInform(user.token, user.status === USER_STATUS.NEW ? 'new' : 'pending')
          }
          
          fetchData()
        }catch(error){
          setLoading(false)
          console.log('No hay evaluaciones para informar')
        }
      }
      await fetchData()
    }
  }

  const goToNewReport = () => {
    setLoading(false)
    history.push('/new-report')
  }

  const goToPatientReport = () => {
    setLoading(false)
    history.push('/report')
  }

  useEffect(() => {
    if(dataLoaded){
      if(user.role === 'patient'){
        goToPatientReport()
      }
      if(user.role === 'ophthalmologist'){
        goToNewReport()
      }
    }
  }, [dataLoaded])

  useEffect(() => {

    const fetchData = async () => {
      if(!dataLoaded){
        if (data === false || data === 'no-data') {
          setLoading(false)
          openModal()
        }
        else if(data){
          dispatch(setEvaluations(data))
          if(user.role === 'ophthalmologist'){
            dispatch(toggleToContinueStatus())
            await completeEvaluationData(data, action)
            setDataLoaded(true)
          }
          if(user.role === 'patient'){
            await completeEvaluationData(data, 'last')
            setDataLoaded(true)
          }
        }

    }
    }
    fetchData()
  }, [data])
  
  if (type === 'evaluations') return (
    <Container>
      <IconWrapper>
        <IconOption src={List} alt='list' />
      </IconWrapper>
      <Link to='/evaluations'>
        <Button> Listado de estudios </Button>
      </Link>
    </Container>
  )

  if (type === 'admin') return (
    <Container>
      <IconWrapper>
        <IconOption src={List} alt='list' />
      </IconWrapper>
      <Link to='/abm'>
        <Button highlighted={highlighted}> {children} </Button>
      </Link>
    </Container>
  )

  return (
    <Container>
      
      <NoStudyModal ref={modalRef} close={closeModal}/>

      {loading && <LoadingScreen/>}

      {user.status === USER_STATUS.LOCKED && (
        <FloatingMessageWithIcon disabled>Esperar Embargo</FloatingMessageWithIcon>
      )}

      {user.status === USER_STATUS.UNLOCK && (
        <FloatingMessage>Tiempo de embargo finalizado</FloatingMessage>
      )}
      
      <IconWrapper>
        <IconOption src={CaseIcon} alt='new' />
      </IconWrapper>
      {user.status === USER_STATUS.LOCKED && (
        <DummyLink>
          <DummyButton> {caseText} </DummyButton>
        </DummyLink>
      )}

      {user.status !== USER_STATUS.LOCKED && (
        <LinkContainer>
          <Button onClick={handleSetEvaluation} highlighted> {caseText} </Button>
        </LinkContainer>
      )}
    </Container>
  )
}

export default UserOption
