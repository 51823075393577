import NonReferableIcon from '../../../../assets/icons/non-referable-icon.svg'
import ReferableIcon from '../../../../assets/icons/referable-icon.svg'

export const STATUS_INFO = {
  referable: {
    icon: ReferableIcon,
    title: 'Referible',
    subtitle1: 'Nuestra IA observa lesiones compatibles con la retinopatía diabética en este examen.',
    subtitle2: ' Se recomienda informe profesional para confirmar el diagnóstico.',
    button1: 'Descargar',
    button2: 'Enviar y Finalizar',
    button3: 'Enviar y referir a Oftalmólogo'
  },
  nonReferable: {
    icon: NonReferableIcon,
    title: 'No referible',
    subtitle1: 'Nuestra IA no observa lesiones compatibles con la retinopatía diabética al momento de este examen.',
    subtitle2: 'No obstante, la imagen debería ser informada por un profesional para confirmar el diagnóstico.',
    button1: 'Descargar',
    button2: 'Referir a Oftalmólogo',
    button3: 'Enviar y Finalizar'
  }
}
