import styled, {css, keyframes} from "styled-components";
import { colors } from "../../const";

export const SpinnerContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    `;

const spin = keyframes`
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
    `;


export const Spinner = styled.div(({size}) => css`
    border: 3px solid #f3f3f3;
    border-top: 3px solid ${colors.primary};
    border-radius: 50%;
    width: ${size}px;
    height: ${size}px;
    animation: ${spin} 1s linear infinite;
    `);