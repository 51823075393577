export function DxResume({ia, dr}){
    if(dr===null){
        return(
            <p>{ia?'Presunto Caso Referible':'Presunto Caso No Referible'}</p>
        )
    }else{
        return(
            <p>{dr?'Con Riesgo':'Sin Riesgo'}</p>
        )
    }
}