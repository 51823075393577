import { forwardRef } from 'react'
import { Modal } from '../../Modal'
import { useDeleteStudy } from '../../../hooks/NewCase/Service/useDeleteStudy'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setResetCases } from '../../../redux/cases'
import { resetImages } from '../../../redux/images'
import { resetEvaluations } from '../../../redux/evaluations'
import { stepReset } from '../../../redux/steps'
import { useSelector } from 'react-redux'
import { toggleToNewStatus } from '../../../redux/user'
import { removeIncompleteEvaluations } from '../../../redux/user'
import {
  ModalTitle,
  ModalDescription,
  ButtonsWrapper,
  Button
} from './styles'

export const CancelCaseModal = forwardRef(({ close }, ref) => {
  const { evaluations, steps } = useSelector(state => state)
  const dispatch = useDispatch()
  const history = useHistory()
  const { deleteStudy } = useDeleteStudy()

  
  const handleCancelCase = async () => {
    dispatch(setResetCases())
    dispatch(resetImages())
    dispatch(stepReset())
    dispatch(resetEvaluations())
    dispatch(toggleToNewStatus())
    dispatch(removeIncompleteEvaluations())
    evaluations && steps.current==3 && await deleteStudy(evaluations.id)
    history.replace('/home')
  }

  return (
    <Modal ref={ref}>
      <ModalTitle> Cancelar Estudio </ModalTitle>
      <ModalDescription>
        Si cancelás el estudio, se perderán todos los datos cargados hasta el momento.
      </ModalDescription>
      <ButtonsWrapper>
        <Button onClick={close}> Volver </Button>
        <Button highlighted onClick={handleCancelCase}> Cancelar estudio </Button>
      </ButtonsWrapper>
    </Modal>
  )
})

export default CancelCaseModal
