import { useSelector } from "react-redux"
import { useState } from "react"
import { URLS } from "../../const/nav"
export const useGetInformFromStudy = () => {

    const { user } = useSelector(state => state)
    const [inform, setInform] = useState(null)

    const getInformFromStudy = async (id) => {
        const response = await fetch(`${URLS.BACKEND_URL}/informes/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${user.token}`
            }
        })
        const data = await response.json()
        setInform(data)
    }

    return {inform, getInformFromStudy}

}
