export const DATA = {
    StepOne: {
        dni: false,
        genero: false,
    },
    PhaseOne: {
        nombre:false,
        apellido:false,
        fechaNacimiento:false,
        telefono:false,
        domicilio:false,
        piso:false,
        departamento:false,
        email:false,
        localidad:false,
        tipoSeguridadSocial:false,
        codigoAfiliado:false,
    },
    PhaseTwo: {
        fecha: false,
        paciente_id:false,
        tipo:false,
        estado:false,
        informe:false,
        revisarProfesional:false,
        altura:false,
        peso:false,
        informacionAdicional:false
    }
}