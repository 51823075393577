import { Login, Home, NewCase, Evaluations, NewReport, ABM } from './views';
import { Provider as ReduxProvider } from 'react-redux';
import { useSelector } from 'react-redux';
import Pdf from './components/Pdf';
import { Data, Layout } from './components';
import { useValidateToken } from './hooks/useValidateToken';
import {
  BrowserRouter,
  Switch,
  Route,
  Redirect
} from 'react-router-dom';
import store from './redux/store';
import { PatientReport } from './views/PatientReport';

const userRolePaths = {
  technical: ['/home', '/new-case', '/evaluations'],
  patient: ['/home', '/report', '/evaluations', '/pdf'],
  ophthalmologist: ['/home', '/new-report', '/evaluations', '/report'],
}

function userHasAuthorization(path) {
  const { user } = useSelector(state => state);
  const { role } = user;
  console.log('role', role) 
  return userRolePaths[role].includes(path);
}

function PrivateRoute({ children, path }) {

  const LIST_OF_PATHS = ['/home', '/new-case', '/evaluations', '/new-report', '/report','/abm', '/pdf', '/login'];

  const { validate } = useValidateToken();

  const { isLogged } = validate();


  const isPathValid = LIST_OF_PATHS.includes(path);

  if(!isLogged && isPathValid) return <Redirect to='/login' />
  else if(isLogged && path === '/login') return <Redirect to='/home' />
  else if(isLogged && !isPathValid) return <Redirect to='/home' />
  else if(isLogged && !userHasAuthorization(path)) return <Redirect to='/home' />
  else return <Route path={path}>{children}</Route>
}

function App() {
  return (
    <ReduxProvider store={store}>
      <BrowserRouter>
        <Switch>
          <Route path="/login">
            <Login />
          </Route>
          <Layout>
            <PrivateRoute path="/home">
              <Home />
            </PrivateRoute>
            <PrivateRoute path="/new-case">
              <NewCase />
            </PrivateRoute>
            <PrivateRoute path="/evaluations">
              <Evaluations />
            </PrivateRoute>
            <PrivateRoute path="/new-report">
              <NewReport />
            </PrivateRoute>
            <PrivateRoute path="/report">
              <PatientReport />
            </PrivateRoute>
            <PrivateRoute path="/abm">
              <ABM />
            </PrivateRoute>
            <PrivateRoute path="/pdf">
              <Pdf />
            </PrivateRoute>
            <PrivateRoute/>
          </Layout>
        </Switch>
      </BrowserRouter>
    </ReduxProvider>
  );
}

export default App;
