import styled from 'styled-components'
import { colors } from '../../const'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 1380px;
  padding: 1% 2%;
  width: 100%;
`

export const ReportWrapper = styled.section`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

export const CaseId = styled.section`
  color: ${colors.lightBlack};
  font-family: 'Roboto';
  font-size: 1.5rem;
  font-weight: 700;
  margin: 14px 0 22px;
`
