import { useEffect, useRef } from 'react'
import Cross from '../../assets/icons/cross.png'
import {
  PositionContainer,
  Container,
  LeftBorder,
  Text,
  IconWrapper,
  Icon
} from './styles'

export function Snackbar ({ status, close, isVisible = true, children }) {
  const snackbarRef = useRef(null)

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)

    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [])

  const handleClickOutside = (event) => {
    if (snackbarRef && !snackbarRef.current.contains(event.target)) {
      close()
    }
  }

  return (
    <PositionContainer isVisible={isVisible}>
      <Container ref={snackbarRef}>
        <LeftBorder status={status} />
        <Text> {children} </Text>
        <IconWrapper>
          <Icon src={Cross} alt='close' onClick={close} />
        </IconWrapper>
      </Container>
    </PositionContainer>
  )
}

export default Snackbar
