import styled, { css, keyframes } from 'styled-components'
import { STATUS } from '../../../../const/status'


export const PrincipalImage = styled.img(({status}) => css`
  width: 100%;
  max-height: 100%;
  max-width: 100%;
  border: 3px solid ${STATUS[status]};
  cursor: zoom-in;
  object-fit: contain;
`)

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`

export const SpinnerLoading = styled.div`
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid #3498db;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  position: absolute;
  top: 50%;
  left: 50%;
  animation: ${spin} 2s linear infinite;
`