import { useState } from "react"

export const useStatisticCases = () => {

    const [ statistics, setStatistics ] = useState({
        total: 0,
        percentage: 0
    });

    const getStatistics = (cases) => {
        // Obtener la fecha actual
        const currentDate = new Date();
        const currentMonth = currentDate.getMonth();
        const currentYear = currentDate.getFullYear();
    
        // Filtrar casos para el mes actual
        const currentMonthCases = cases.filter((caseItem) => {
          const caseDate = new Date(caseItem.fecha);
          return caseDate.getMonth() === currentMonth && caseDate.getFullYear() === currentYear;
        });
    
        // Filtrar casos para el mes anterior
        const previousMonth = (currentMonth - 1 + 12) % 12; // Manejar el cambio de año
        const previousMonthCases = cases.filter((caseItem) => {
          const caseDate = new Date(caseItem.fecha);
          return caseDate.getMonth() === previousMonth && caseDate.getFullYear() === (currentMonth === 0 ? currentYear - 1 : currentYear);
        });
    
        const total = currentMonthCases.length;
        const previousTotal = previousMonthCases.length;
        const percentage = previousTotal !== 0 ? (total / previousTotal) * 100 : 0;
    
        // Actualizar el estado
        setStatistics({
          total,
          percentage
        });
      };

    return { statistics, getStatistics };
}
