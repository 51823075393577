import { useState } from 'react'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import Button from '../../components/Button'
import PaginationFooter from '../../components/PaginationFooter'
import { mock_users } from './mock.data'
import ABMIcon from '../../assets/icons/abm.svg'
import EditIcon from '../../assets/icons/pencil-blue.svg'
import RemoveIcon from '../../assets/icons/trash.png'
import {
  Container,
  Header,
  TitleWrapper,
  TitleIcon,
  Title,
  Subheader,
  SearchWrapper,
  Input,
  FilterWrapper,
  SelectWrapper,
  SelectLabel,
  Select,
  Option,
  Table,
  TableHeader,
  TableHeaderData,
  TableBody,
  TableBodyRow,
  TableBodyCell,
  Thumbnail,
  IconsWrapper,
  Icon,
} from './styles'

const PAGES = [1, 2, 3, 4]

export const ABM = () => {
  const { user } = useSelector(state => state)
  const [currentPage, setCurrentPage] = useState(PAGES[0])

  const handleChangePage = (page) => {
    setCurrentPage(page)
  }

  if (user.role !== 'admin') return <Redirect to='/home' />

  return (
    <Container>
      <Header>
        <TitleWrapper>
          <TitleIcon src={ABMIcon} alt='abm-icon'/>
          <Title> ABM de Técnicos </Title>
        </TitleWrapper>

        <Button color='primary'> Agregar nuevo técnico </Button>
      </Header>

      <Subheader>
        <SearchWrapper>
          <Input placeholder={user.role === 'patient' ? 'Buscar...' : 'Buscar'} />
        </SearchWrapper>

        <FilterWrapper>
          <SelectWrapper>
            <SelectLabel> Periodo </SelectLabel>
            <Select>
              <Option> Último mes </Option>
            </Select>
          </SelectWrapper>

          <SelectWrapper>
            <SelectLabel> Estado </SelectLabel>
            <Select>
              <Option> Todos los estados </Option>
            </Select>
          </SelectWrapper>
        </FilterWrapper>
      </Subheader>

      <Table>
        <TableHeader>
          <TableHeaderData> {/* empty, avatar */} </TableHeaderData>
          <TableHeaderData> Nombre </TableHeaderData>
          <TableHeaderData> Email </TableHeaderData>
          <TableHeaderData> Teléfono </TableHeaderData>
          <TableHeaderData> DNI </TableHeaderData>
          <TableHeaderData> Fecha de Alta </TableHeaderData>
          <TableHeaderData> {/* empty, icons */} </TableHeaderData>
        </TableHeader>

        <TableBody>
          {mock_users.map(user => (
            <TableBodyRow key={user.id}>
              <Thumbnail src={user.image} alt='thumbnail' />
              <TableBodyCell> {user.name} </TableBodyCell>
              <TableBodyCell> {user.email} </TableBodyCell>
              <TableBodyCell> {user.phoneNumber} </TableBodyCell>
              <TableBodyCell> {user.dni} </TableBodyCell>
              <TableBodyCell> {user.date} </TableBodyCell>
              <IconsWrapper>
                <Icon src={EditIcon} alt='edit' />
                <Icon src={RemoveIcon} alt='remove' />
              </IconsWrapper>
            </TableBodyRow>
          ))}
        </TableBody>

        <PaginationFooter
          currentPage={currentPage}
          listOfPages={PAGES}
          handleChangePage={handleChangePage}
        />
      </Table>
    </Container>
  )
}

export default ABM
