import { Status } from './Status'
import { Loader } from './Loader'
import { useImageLoading } from '../../../hooks/useImageLoading'
import{ PrincipalImageStatus} 
    from './styles'

    export function ImagePercentage({ loading, image }) {
      return (
          <PrincipalImageStatus>
              {loading ? <Loader /> : <Status image={image} />}
          </PrincipalImageStatus>
      );
  }
  