import { useState, forwardRef, useImperativeHandle, useEffect } from 'react'
import { useSelector } from 'react-redux'
import ReactDOM from 'react-dom'
import CloseIcon from '../../assets/icons/cross.png'
import { Container, ModalTemplate, Icon } from './styles'

export const Modal = forwardRef(({ children, focus=true, close=null , imageControl=false }, ref) => {
  const [display, setDisplay] = useState(false)
  const { user } = useSelector(state => state)
  useImperativeHandle(ref, () => {
    return {
      show: (action) => show(action),
      hide: () => hide()
    }
  })

  useEffect(() => {
    const close = e => {
      if (e.key === 'Escape') {
        hide()
      }
    }
    window.addEventListener('keydown', close)
    return () => window.removeEventListener('keydown', close)
  }, [])

  const show = (action) => {
    setDisplay(true)
  }

  const hide = () => {
    setDisplay(false)
  }

  const handleHide = () => {
    if(!close || user.role === 'technical')
      hide()
    else
      close()
  }

  const content = (
    <Container focus = {focus} onClick={handleHide}>
      <ModalTemplate focus = {focus} imageControl={imageControl} onClick={(e) => e.stopPropagation()}>
        {!close&&<Icon src={CloseIcon} onClick={hide} />}
        {children}
      </ModalTemplate>
    </Container>
  )

  if (display) {
    return ReactDOM.createPortal(content, document.getElementById('modal-root'))
  }

  return null
})

export default Modal
