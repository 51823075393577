import styled, { css } from 'styled-components'
import { colors } from '../../../const'

export const ModalTitle = styled.h1`
  color: ${colors.darkBlack};
  font-size: 1.6875rem;
  margin: 0;
  text-align: center;
`

export const ModalDescription = styled.p`
  color: ${colors.lightBlack};
  line-height: 24px;
  font-size: 1rem;
  text-align: center;
`

export const ButtonsWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: 30px;
  width: 100%;
`

export const Button = styled.button(
  ({ highlighted }) => css`
    align-items: center;
    background-color: ${highlighted ? '#F93428' : colors.secondary};
    border: none;
    border-radius: 6px;
    color: ${highlighted ? colors.white : '#005EC4'};
    cursor: pointer;
    display: flex;
    font-family: 'Inter';
    font-size: 0.875rem;
    font-weight: 500;
    height: 40px;
    justify-content: center;
    margin: 0 2%;
    max-width: 130px;
    width: 100%;
  `
)
