import OpenEyeIcon from '../../../assets/icons/open_eye.svg'
import CloseEyeIcon from '../../../assets/icons/close_eye.svg'
import {
  PlainContainer,
  ReportContainer,
  StatusContainer,
  ExamIcon,
  StatusWrapper,
} from './styles'

export function InformationCell ({ children, type, examType, reportActive, onClick, status, iaDx, drDx }) {

  const report = reportActive==='Informado'? true : false;

  if (type === 'dx') return (
    <StatusContainer>
      <StatusWrapper dr={drDx}>
        {children}
      </StatusWrapper>
    </StatusContainer>
  )

  if (type === 'status') return (
    <ReportContainer onClick={onClick} active={report}>
      {children}
    </ReportContainer>
  )

  if (type === 'exam') return (
    <PlainContainer>
      {examType === 1 || examType === 0
        ? <ExamIcon width='20' height='16' src={OpenEyeIcon} alt='exam-icon'/>
        : <ExamIcon width='20' height='16' src={CloseEyeIcon} alt='exam-icon'/>
      }
      {examType === 2 || examType === 0
        ? <ExamIcon width='20' height='16' src={OpenEyeIcon} alt='exam-icon'/>
        : <ExamIcon width='20' height='16' src={CloseEyeIcon} alt='exam-icon'/>
      }
      {children}
    </PlainContainer>
  )

  return (
    <PlainContainer>
      {children}
    </PlainContainer>
  )
}

export default InformationCell
