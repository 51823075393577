import styled from "styled-components";
import { colors } from "../../../../const";


export const LoaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 5px;
    align-items: center;
    width: 100%;
    `;

export const LoaderText = styled.p`
    color: ${colors.lightBlack};
    font-size: 0.875rem;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 0;
    padding-top: 2%;
    `;