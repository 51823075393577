import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { URLS } from '../../../const/nav';
export const useUploadImage = () => {
    const {evaluations, user} = useSelector(state => state);
    const uploadImage = async (file, whichEye) => {
        try {
            const formData = new FormData();
            formData.append('imagen', file);
            formData.append('upload_preset', 'ml_default');
            formData.append('ojo', whichEye=='left'?0:1)

            console.log('ojo: ' + whichEye)

            const r = await fetch(`${URLS.BACKEND_URL}/imagenes/subir/${evaluations.id}`, {
                method: 'POST',
                headers: {
                    'Authorization': `${user.token}`
                },
                body: formData
            });

            if (r.status === 404) {
                console.log('Imagen no encontrada.');
                return null; 
            } else if (r.ok) {
                return r.json();
            } else {
                // Otro manejo de errores aquí
                console.error(`Error en la solicitud: ${r.status}`);
                return null;
            }
        } catch (error) {
            // Manejo de errores generales aquí
            console.error('Error al realizar la solicitud:', error);
            return null;
        }
    }
    return { uploadImage };

}
