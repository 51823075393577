import styled, { css } from 'styled-components'
import { colors } from '../../../const'

const getStatusBackground = (dr) => {
  if(dr===null){
    return 'rgba(254, 197, 20, 0.2)' 
  }else{
    return dr==='positive'?'rgba(255, 0, 0, 0.2)':'rgba(230, 249, 247, 0.8)'
  }
}

const getStatusColor = (dr) => {
  if(dr===null){
    return '#F9B110' 
  }else{
    return dr?'#FF0000':'#06BF3A'
  }
}

export const PlainContainer = styled.div`
  align-items: center;
  color: ${colors.lightBlack};
  display: flex;
  gap: 6px;
`

export const ReportContainer = styled.div(
  ({ active }) => css`
    border-left: 0.5px solid #ABB4C4;
    padding: 14px 0 14px 20px;
    text-align: center;
    align-items: center;
    color: ${ active ? '#0077CC' : '#ABB4C4'};
    cursor: ${active ? 'pointer' : 'inherit'};
    display: flex;

    &:hover {
      text-decoration: ${active ? 'underline' : 'none'};
    }
  `
)

export const StatusContainer = styled.div`
  border-left: 0.5px solid #ABB4C4;
  padding: 14px 0 14px 20px;
  text-align: center;
  text-transform: uppercase;
`

export const StatusWrapper = styled.div(
  ({ dr }) => css`
    align-items: center;
    background-color: ${getStatusBackground(dr)};
    border-radius: 3px;
    color: ${getStatusColor(dr)};
    display: flex;
    font-size: 0.75rem;
    font-weight: 500;
    justify-content: center;
    height: 29px;
    // line-height: 29px;
    max-width: 115px;
    width: 100%;
  `
)

export const ExamIcon = styled.img`
  max-height: 16px;
`
