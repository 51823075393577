import Image1 from '../../assets/thumbnails/1.png'
import Image2 from '../../assets/thumbnails/2.png'
import Image3 from '../../assets/thumbnails/3.png'
import Image4 from '../../assets/thumbnails/4.png'
import Image5 from '../../assets/thumbnails/5.png'
import Image6 from '../../assets/thumbnails/6.png'
import Image7 from '../../assets/thumbnails/7.png'
import Image8 from '../../assets/thumbnails/8.png'

export const mock_users = [
  { id: 1, name: 'Juan', email: 'Juan@gmail.com', phoneNumber: '(939)-555-0113', dni: '25876998', date: '08-Dec, 2021', image: Image1 },
  { id: 2, name: 'Juani', email: 'Juani@gmail.com', phoneNumber: '(939)-555-0113', dni: '25876998', date: '08-Dec, 2021', image: Image2 },
  { id: 3, name: 'Juana', email: 'Juana@gmail.com', phoneNumber: '(939)-555-0113', dni: '25876998', date: '08-Dec, 2021', image: Image3 },
  { id: 4, name: 'Joana', email: 'Joana@gmail.com', phoneNumber: '(939)-555-0113', dni: '25876998', date: '08-Dec, 2021', image: Image4 },
  { id: 5, name: 'Jessica', email: 'Jessica@gmail.com', phoneNumber: '(939)-555-0113', dni: '25876998', date: '08-Dec, 2021', image: Image5 },
  { id: 6, name: 'Jahir', email: 'Jahir@gmail.com', phoneNumber: '(939)-555-0113', dni: '25876998', date: '08-Dec, 2021', image: Image6 },
  { id: 7, name: 'Jose', email: 'Jose@gmail.com', phoneNumber: '(939)-555-0113', dni: '25876998', date: '08-Dec, 2021', image: Image7 },
  { id: 8, name: 'Jorge', email: 'Jorge@gmail.com', phoneNumber: '(939)-555-0113', dni: '25876998', date: '08-Dec, 2021', image: Image8 },
]
