import { Container } from './styles'

export function StatusChip ({ type, size, children }) {
  return (
    <Container type={type} size={size}>
      {children}
    </Container>
  )
}

export default StatusChip
