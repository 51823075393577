export const withScrollbarStyled = `
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    box-shadow: inset 0 0 5px #EEE;
    padding-left: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: #AAA;
    border-radius: 10px;
  }
`
