import styled from "styled-components";
import { colors } from "../../const";

export const Container = styled.div`   
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: 1380px;
    align-items: center;
    justify-content: center;
    padding: 1% 2%;
    width: 100%;
    height: 80%;
`

export const ReportWrapper = styled.section`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2%;
    width: 100%;
`

export const Title = styled.h1`
    color: ${colors.primary};
    font-family: 'Roboto';
    font-size: 1.5rem;
    font-weight: 700;
    text-align: center;
    margin: 14px 0 22px;`

