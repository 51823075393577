import { useDispatch } from 'react-redux'
import { stepIncrement, stepComplete } from '../../../../redux/steps'
import StatusChip from '../../../StatusChip'
import { Button } from '../../../Button'
import { STATUS_INFO } from './helpers'
import { useFinishStudy } from '../../../../hooks/NewCase/useFinishStudy'
import { toggleToNewStatus } from '../../../../redux/user'
import { useUpdateUserEvaluations } from '../../../../hooks/useUpdateUserEvaluations'
import { resetEvaluations } from '../../../../redux/evaluations'
import { removeIncompleteEvaluations } from '../../../../redux/user'
import {
  Container,
  StatusImage,
  StatusTitle,
  StatusSubtitle,
  StatusChipText,
  FooterWrapper,
  ObservationTitle,
  ObservationTextArea,
  ButtonsWrapper
} from './styles'

export function AnalysisResult ({ status }) {
  const dispatch = useDispatch()
  const analysisStatus = STATUS_INFO[status]
  const { finishStudy } = useFinishStudy()
  // const { updateUserEvaluations } = useUpdateUserEvaluations()

  const handleSendCase = async (button) => {
    if(button.includes('Oftalmólogo')){
      await finishStudy(true)
    }else{
      await finishStudy(false)
    }
    dispatch(removeIncompleteEvaluations())
    dispatch(resetEvaluations())
    dispatch(toggleToNewStatus())
    dispatch(stepComplete())
  }

  return (
    <Container>
      <StatusImage height='66' src={analysisStatus.icon} />
      <StatusTitle> {analysisStatus.title} </StatusTitle>

      <StatusSubtitle> {analysisStatus.subtitle1} </StatusSubtitle>
      <StatusSubtitle> {analysisStatus.subtitle2} </StatusSubtitle>

      <FooterWrapper>
        <StatusChip type='primary' size='small'>
          <StatusChipText> Esta evaluación no reemplaza el diagnóstico de un oftalmólogo. </StatusChipText>
          <StatusChipText> Es una valoración de la IA de Retinar. </StatusChipText>
        </StatusChip>

        <ObservationTitle> Si querés hacer alguna observación, por favor, escribila acá: </ObservationTitle>
        <ObservationTextArea />

        <ButtonsWrapper>
          <Button color='secondary'> {analysisStatus.button1} </Button>
          <Button color='secondary' onClick={()=>handleSendCase(analysisStatus.button2)}> {analysisStatus.button2} </Button>
          <Button color='primary' onClick={()=>handleSendCase(analysisStatus.button3)}> {analysisStatus.button3} </Button>
        </ButtonsWrapper>
      </FooterWrapper>

    </Container>
  )
}

export default AnalysisResult
