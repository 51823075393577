import styled from 'styled-components'
import { colors } from '../../const'

export const Container = styled.div`
  background-color: ${colors.background};
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  min-width: 100%;
`

export const Content = styled.div`
  flex-grow: 1;
`
