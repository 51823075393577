import styled, { css } from 'styled-components'

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  height: 720px;
  width: 1280px;
`

export const Header = styled.div`
  background-color: #0071C2;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 20px;
`

export const HeaderItem = styled.div(
  ({ position = 'flex-start' }) => css`
    color: #FFF;
    font-size: 28px;
    justify-self: ${position};
  `
)

export const HeaderDetailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding: 0 10%;
`

export const HeaderDetailRow = styled.div`
  color: #FFF;
  display: flex;
  justify-content: space-between;
  gap: 10px;
`

export const HeaderField = styled.p`
  font-weight: 600;
  margin: 0;
`

export const HeaderValue = styled.p`
  margin: 0;
`

