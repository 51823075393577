import CheckIcon from '../../../../../assets/icons/check_bg_green.svg'
import {
  Container,
  SuccessImage,
  SuccessWrapper,
  SuccesImageWrapper,
  SuccessTitle,
  ContinueSubtitle,
  ButtonsWrapper,
  Button,
  Link
} from './styles'

export function PhaseThree ({ nextStep }) {
  return (
    <Container>
      <SuccessWrapper>
        <SuccesImageWrapper>
          <SuccessImage width='57' height='57' src={CheckIcon} alt='success' />
        </SuccesImageWrapper>

        <SuccessTitle> Datos cargados con éxito! </SuccessTitle>

        <ContinueSubtitle> Continuar cargando imágenes </ContinueSubtitle>

        <ButtonsWrapper>
          <Link to='/home'>
            <Button> Volver </Button>
          </Link>

          <Button highlighted onClick={nextStep}> Continuar </Button>
        </ButtonsWrapper>
      </SuccessWrapper>
    </Container>
  )
}

export default PhaseThree
