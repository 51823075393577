import styled, { css } from 'styled-components'
import { colors } from '../../const'
import SearchIcon from '../../assets/icons/search-icon.svg'

const GRID_SPACES_HEADER_BODY = (role) =>{
 return role ==='patient'?'1fr 1.2fr 1fr 1fr':'1fr 1.2fr 0.8fr 1.5fr 1fr 1fr'
} 

export const Container = styled.div`
  font-family: 'Inter';
  margin: 0 auto;
  max-width: 1600px;
  padding: 4% 5% 2%;
  width: 100%;
`

export const Header = styled.div`
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  width: 100%;
`

export const TitleWrapper = styled.div`
  align-items: center;
  display: flex;
  margin-top: 2%;
`

export const TitleIcon = styled.img`

`

export const Title = styled.h1`
  font-size: 1.75rem;
  font-weight: 700;
  margin: 0 0 0 20px;
`

export const MonthlyCasesWrapper = styled.div`
  background-color: ${colors.white};
  box-shadow:
    0px 0.9px 4px -1px rgba(0, 0, 0, 0.08),
    0px 2.6px 8px -1px rgba(0, 0, 0, 0.06),
    0px 5.7px 12px -1px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  height: 110px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 18px;
  width: 180px;
`

export const MonthlyTitle = styled.h2`
  color: ${colors.lightBlack};
  font-size: 0.75rem;
  font-weight: 500;
  margin: 0;
  width: 100%;
`

export const MonthlyValues = styled.div`
  align-items: center;
  display: flex;
  width: 100%;
`

export const MonthlyTotal = styled.p`
  color: ${colors.primary};
  font-size: 2rem;
  font-weight: 700;
  margin: 0;
`

export const MonthlyPercentageWrapper = styled.div`
  align-items: center;
  background: rgba(0, 102, 177, 0.1);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  margin: 0 10px;
  padding: 4px 2px;
`

export const MonthlyPercentage = styled.p`
  color: ${colors.primary};
  font-size: 0.625rem;
  font-weight: 700;
  margin: 0 4px;
`

export const MonthlyPercentageIcon = styled.img`
  margin-left: 4px;
`

export const Subheader = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
`

export const SearchWrapper = styled.form`
  background: ${colors.white};
  border: 1px solid rgba(19, 34, 149, 0.1);
  border-radius: 6px;
  height: 40px;
  max-width: 40%;
  position: relative;
  width: 100%;

  &::before {
    content: url(${SearchIcon});
    left: 10px;
    position: absolute;
    top: 50%;
    transform: translateY(-40%);
  }
`

export const Input = styled.input`
  background: ${colors.white};
  border: none;
  border-radius: 6px;
  color: ${colors.lightBlack};
  height: 100%;
  font-size: 0.875rem;
  line-height: 21px;
  padding-left: 36px;
  width: 100%;

  &::placeholder {
    color: rgba(52, 55, 65, 0.8);
  }

  &:focus {
    &::placeholder {
      color: transparent;
    }
  }
`

export const FilterWrapper = styled.div`
  display: flex;
  gap: 20px;
  justify-content: flex-end;
  width: 100%;
`

export const SelectWrapper = styled.div`
  align-items: center;
  display: flex;
`

export const SelectLabel = styled.label`
  font-family: 'Inter';
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 18px;
`

export const Select = styled.select`
  background-color: transparent;
  border: none;
  color: #0077CC;
  cursor: pointer;
  font-family: 'Inter';
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 18px;
  margin-left: 6px;
  outline: none;
  padding-right: 16px;
`

export const Option = styled.option`
  
`

export const MainContent = styled.section`
  background: ${colors.white};
  border-radius: 5px;
  box-shadow:
    0px 1px 4px -1px rgba(0, 0, 0, 0.08),
    0px 3px 8px -1px rgba(0, 0, 0, 0.06),
    0px 6px 12px -1px rgba(0, 0, 0, 0.05);
  margin-top: min(2%, 26px);
  padding: min(2%, 32px);
  width: 100%;
`

export const Table = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const TableHeader = styled.div(
  ({ role }) => css`
  display: grid;
  grid-template-columns: ${GRID_SPACES_HEADER_BODY(role)};
  width: 100%;
`)

export const TableHeaderData = styled.div(
  ({ statusHeader }) => css`
    border-left: ${statusHeader ? '0.5px solid #ABB4C4' : 'none'};
    font-size: 0.75rem;
    font-weight: 600;
    padding-bottom: 16px;
    padding-left: ${statusHeader ? '20px' : ''};
  `
)

export const TableBodyRow = styled.div(
  ({ role }) => css`
  display: grid;
  grid-template-columns: ${GRID_SPACES_HEADER_BODY(role)};
  width: 100%;
`)
