import styled from 'styled-components'
import { colors, withScrollbarStyled } from '../../../../const'

export const Container = styled.div`
  align-items: center;
  color: ${colors.lightBlack};
  display: flex;
  flex-direction: column;
  font-family: 'Inter';
  justify-content: center;
  padding-bottom: 4%;
  width: 100%;
`

export const StatusImage = styled.img``

export const StatusTitle = styled.h1`
  font-size: 1.75rem;
  font-weight: 700;
  margin: 1% 0;
`

export const StatusSubtitle = styled.h2`
  font-family: 'Open sans';
  font-size: 1rem;
  font-weight: 400;
  line-height: 24px;
  margin: 0;
`

export const StatusChipText = styled.span`
  display: block;
  line-height: 20px;
  width: 100%;
`

export const FooterWrapper = styled.div`
  margin-top: 16px;
  max-width: 520px;
  width: 100%;
`

export const ObservationTitle = styled.p`
  color: ${colors.darkBlack};
  font-family: 'Inter';
  font-size: 0.875rem;
  margin: 30px 0 6px;
`

export const ObservationTextArea = styled.textarea`
  background-color: #FBFCFD;
  border: 1px solid rgba(19, 34, 149, 0.1);
  border-radius: 6px;
  height: 72px;
  padding: 8px 10px;
  resize: none;
  width: 100%;

  ${withScrollbarStyled};
`

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 16px;
  justify-content: center;
  margin-top: 30px;
  width: 100%;
`
