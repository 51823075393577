import Modal from "../.."
import { forwardRef } from "react"
import { ModalDescription, ModalTitle, ButtonsWrapper, Button } from "../styles"
export const NoStudyModal = forwardRef(({close}, ref) => {
    return (
        <Modal ref={ref}>
            <ModalTitle> No hay estudios </ModalTitle>
            <ModalDescription>
                No hay estudios pendientes de informe
            </ModalDescription>
            <ButtonsWrapper>
                <Button highlighted onClick={close}> Volver </Button>
            </ButtonsWrapper>
        </Modal>
    )
})