import { useSelector } from "react-redux"
import { URLS } from "../../const/nav"

export const useInform = () => {
    const { user } = useSelector(state => state)


    const cancelReport = async () => {
            await fetch(`${URLS.BACKEND_URL}/informes/cancelar`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${user.token}`
                }
            })
    }

    const uploadReport = async () => {
        await fetch(`${URLS.BACKEND_URL}/informes/subir`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${user.token}`
            }
        })
    }

    const updateInform = async (inform) => {
        await fetch(`${URLS.BACKEND_URL}/informes/guardar`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${user.token}`
            },
            body: JSON.stringify(inform),
        })
    }


    return {cancelReport, uploadReport, updateInform}
}