
export const useDateFormatter = () => {

    const formatDate = (d) => {
        if(d){
            const dateArray = d.split('-');
            const year = dateArray[0];
            const month = dateArray[1];
            const day = dateArray[2].slice(0,2);
            const fullDate = `${day}/${month}/${year}`;
            return fullDate;
        }
    }

    return { formatDate };
};