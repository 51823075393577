import Modal from "../.."
import { forwardRef } from "react"
import { ModalDescription, ModalTitle, ButtonsWrapper, Button } from "../styles"
export const NewReportModal = forwardRef(({close, modalType, actionType}, ref) => {

    if(modalType === 'send') {
        return(
            <Modal ref={ref}>
                <ModalTitle> Enviar Informe </ModalTitle>
                <ModalDescription>
                    ¿Estás seguro de que quieres enviar el informe?
                </ModalDescription>
                <ButtonsWrapper>
                    <Button onClick={close}> Volver </Button>
                    <Button highlighted={modalType} onClick={actionType}> Enviar </Button>
                </ButtonsWrapper>
            </Modal>
        )
    }

    if(modalType === 'cancel') {
        return(
            <Modal ref={ref}>
                <ModalTitle> Cancelar Informe </ModalTitle>
                <ModalDescription>
                    ¿Estás seguro de que quieres cancelar el informe?
                </ModalDescription>
                <ModalDescription>
                    Cancelar informe implica que se penalizará la cuenta por 24 horas.
                </ModalDescription>
                <ButtonsWrapper>
                    <Button onClick={close}> Volver </Button>
                    <Button highlighted={modalType} onClick={actionType}> Cancelar </Button>
                </ButtonsWrapper>
            </Modal>
        )
    }

    return (
        <Modal ref={ref}>
        </Modal>
    )
})