import { useHistory } from 'react-router-dom'
import { useStepper } from '../../../hooks/useStepper'
import { useEffect, useState } from 'react'
import { SessionExpired } from '../../SessionExpired'
import { useRequiered } from '../../../hooks/NewCase/Requiered/useRequiered'
import {
  Container,
  SearchUserTitle,
  InputWrapper,
  Label,
  Input,
  RadioWrapper,
  RadioOptions,
  RadioInput,
  RadioLabel,
  ButtonsWrapper,
  Button,
  TopContainer,
  ContainterOptions
} from './styles'

export function StepOne () {
  const { handleNextStep } = useStepper()
  const history = useHistory()
  const { isValid, dataError, checkRequired, buttonAvailable} = useRequiered("StepOne")
  const [data, setData] = useState({
    dni:'',
    genero:''
  })

  const handleGoToHome = () => {
    history.push('/home')
  }

  const handleSearchPatient = () => {
    checkRequired(data)    
  }

  const changeHandler = event => {
    setData(prevData => {
      const newData = { ...prevData, [event.target.name]: event.target.value };
      checkRequired(newData, false, true);
      return newData;
    });
  };
  
  useEffect(() => {
    if(isValid){
      sessionStorage.setItem('_patient', JSON.stringify(data))
      handleNextStep()
    }else{
      return
    }
  }, [isValid])


  return (
    <Container>
      <SessionExpired />
      <SearchUserTitle> Clave identificatoria </SearchUserTitle>

      <TopContainer>

      <InputWrapper>
        <Label highlighted error={dataError.dni} > DNI </Label>        
        <Input error={dataError.dni} placeholder='1234567890' name='dni' onChange={changeHandler} required/>
      </InputWrapper>

     
        <RadioWrapper>
          <Label error={dataError.genero}> Género </Label>
          <RadioOptions>
            <ContainterOptions>
            <RadioLabel>
              <RadioInput type='radio' name='genero' value='Femenino' onChange={changeHandler}/>
              Femenino
            </RadioLabel>
            </ContainterOptions>
            <ContainterOptions>
            <RadioLabel>
              <RadioInput type='radio' name='genero' value='Masculino' onChange={changeHandler}/>
              Masculino
            </RadioLabel>
            </ContainterOptions>
            <ContainterOptions>
            <RadioLabel>
              <RadioInput type='radio' name='genero' value='X' onChange={changeHandler}/>
              X
            </RadioLabel>
            </ContainterOptions>
            <ContainterOptions>  
            <RadioLabel>
              <RadioInput type='radio' name='genero' value='No especifica' onChange={changeHandler}/>
              No especifíca
            </RadioLabel>
            </ContainterOptions>
            
          </RadioOptions>
        </RadioWrapper>
      </TopContainer>

        <ButtonsWrapper>
          <Button onClick={handleGoToHome}> Volver </Button>
          <Button highlighted available={buttonAvailable} onClick={handleSearchPatient}> Buscar </Button>
        </ButtonsWrapper>

    </Container>
  )
}

export default StepOne
