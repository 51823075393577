import { PatientInformation, Data, IAQualityControl } from '../../components'
import { SessionExpired } from '../../components/SessionExpired'
import { useSelector } from 'react-redux'
import {
    PatientContainer,
    ReportWrapper,
    CaseId
} from './styles'

export const PatientReport = () => {
    const { evaluations, user } = useSelector(state => state)
    console.log('eeee',evaluations)
    return (
        <PatientContainer>
        <SessionExpired />
        <PatientInformation study={evaluations} patientData={user.data} withEvaluationsButton />
        <ReportWrapper>
            <Data study={user.role!=='patient'?evaluations.informInfo:evaluations} patientData={user.role!=='patient'?evaluations.patientInfo:user.data}/>
            <IAQualityControl study={evaluations} />
        </ReportWrapper>
        </PatientContainer>
  )
}