import { ContainerStatus, TittlePercentage } from './styles';
import { PercentageValues } from '../PercentageValues';

export const Status = (image) => {
    return(
        <>
        <ContainerStatus>
        <TittlePercentage> Iluminacion </TittlePercentage>
          <PercentageValues values={image && image.image.iluminacion}/>
        </ContainerStatus>
        <ContainerStatus>
        <TittlePercentage> Contraste </TittlePercentage>
          <PercentageValues values={image && image.image.contraste}/>
        </ContainerStatus>
        <ContainerStatus>
        <TittlePercentage> Enfoque </TittlePercentage>
          <PercentageValues values={image && image.image.enfoque}/>
        </ContainerStatus>
        </>
    )
}