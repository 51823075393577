import styled, { css } from 'styled-components'
import primaryIcon from '../../assets/icons/infoblue.svg'
import successIcon from '../../assets/icons/checkgreen.svg'
import errorIcon from '../../assets/icons/warningred.svg'
import emptyIcon from '../../assets/icons/infogray.svg'

const getTypeIcon = (type) => {
  if (type === 'primary') return primaryIcon
  if (type === 'success') return successIcon
  if (type === 'error') return errorIcon
  return emptyIcon
}

const getTypeColor = (type) => {
  if (type === 'primary') return '#0077CC'
  if (type === 'success') return '#06BF3A'
  if (type === 'error') return '#F93428'
  return '#ABB4C4'
}

const getTypeBackground = (type) => {
  if (type === 'primary') return '#E6F1FA'
  if (type === 'success') return '#E6F9F7'
  if (type === 'error') return '#F8E9E9'
  return '#FAFBFD'
}

const getFontSize = (size) => {
  if (size === 'small') return '0.875rem'
  return '1rem'
}

export const Container = styled.div(
  ({ type, size = 'medium' }) => css`
    background-color: ${getTypeBackground(type)};
    border-radius: 3px;
    color: ${getTypeColor(type)};
    display: flex;
    flex-direction: column;
    font-size: ${getFontSize(size)};
    font-weight: 500;
    margin: 10px 0;
    padding: 15px 2% 15px 34px;
    position: relative;
    width: 100%;

    &::after {
      content: url(${getTypeIcon(type)});
      left: 10px;
      position: absolute;
      top: 50%;
      transform: translateY(-40%);
    }
  `
)
